import dayjs from "dayjs";
import {median} from "../../util/numbers.js";
import {WEEK} from "../../util/time-and-date.js";

export const ACTIONS = {
    PEE: 'PEE',
    POOP: 'POOP',
    FOOD: 'FOOD',
    CLOCK: 'CLOCK',
}
export const LOCATIONS = {
    OUTSIDE: 'OUTSIDE',
    INSIDE: 'INSIDE',
    TERRACE: 'TERRACE',
}

export const analyzeData = (data, fromTimeAgo = 2 * WEEK, toTimeAgo = 0) => {

    const dataFromLastTwoWeeks = data.filter(entry => {
        const diff = dayjs().diff(dayjs(entry.datetime))
        return diff < fromTimeAgo && diff >= toTimeAgo
    })

    const foodLog = dataFromLastTwoWeeks.filter(entry => entry.action === ACTIONS.FOOD)
    const poopLog = dataFromLastTwoWeeks.filter(entry => entry.action === ACTIONS.POOP)
    const peeLog = dataFromLastTwoWeeks.filter(entry => entry.action === ACTIONS.PEE)
    const accidents = dataFromLastTwoWeeks.filter(isAccident)

    const foodTimeDiffs = getTimeDiffs(foodLog)
    const poopTimeDiffs = getTimeDiffs(poopLog)
    const peeTimeDiffs = getTimeDiffs(peeLog)
    const accidentTimeDiffs = getTimeDiffs(accidents)

    // const poopCounter = poopLog.length
    // const peeCounter = peeLog.length

    return {
        foodLog,
        poopLog,
        peeLog,
        foodTimeDiffs,
        poopTimeDiffs,
        peeTimeDiffs,
        msSinceLastFood: dayjs().diff(dayjs(foodLog[0]?.datetime)),
        msSinceLastPoop: dayjs().diff(dayjs(poopLog[0]?.datetime)),
        msSinceLastPee: dayjs().diff(dayjs(peeLog[0]?.datetime)),
        msSinceLastAccident: dayjs().diff(dayjs(accidents[0]?.datetime)),
        msMedianFoodDiff: median(foodTimeDiffs),
        msMedianPoopDiff: median(poopTimeDiffs),
        msMedianPeeDiff: median(peeTimeDiffs),
        msMedianAccidentDiff: median(accidentTimeDiffs),
    }

}

const getTimeDiffs = entries => {
    const timeDiffs = []
    const dateTimes = entries.map(entry => entry.datetime)
    for(let i = 0; i < dateTimes.length - 1; i++){
        timeDiffs.push(dayjs(dateTimes[i]).diff(dayjs(dateTimes[i + 1])))
    }
    return timeDiffs
}

export const processLog = (data = []) =>
    data.reduce((acc, cur) => {
        const date = dayjs(cur.datetime).format("YYYY-MM-DD")
        if(!acc[date]){
            acc[date] = {
                poops: 0,
                pees: 0,
                foods: 0,
                accidents: 0,
                entries: []
            }
        }
        acc[date].entries.push(cur)
        if(cur.action === ACTIONS.POOP){
            acc[date].poops++
        }
        if(cur.action === ACTIONS.PEE){
            acc[date].pees++
        }
        if(cur.action === ACTIONS.FOOD){
            acc[date].foods++
        }
        if(isAccident(cur)){
            acc[date].accidents++
        }
        return acc
    }, {})

export const isAccident = ({action, location}) =>
    [ACTIONS.POOP, ACTIONS.PEE].includes(action) && [LOCATIONS.INSIDE].includes(location)

export const injectTimeDiffs = (data, action, timeDiffs) => {
    let i = 0
    return data.map(entry => {
        if(entry.action === action){
            entry.timeDiff = timeDiffs[i]
            i++
        }
        return entry
    })
}