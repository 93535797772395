import Button from "../util/Button.jsx";
import PlusIcon from "mdi-react/PlusIcon.js";
import {ACTIONS, LOCATIONS} from "./_helpers.js";
import ActionIcon from "./ActionIcon.jsx";
import LocationIcon from "./LocationIcon.jsx";
import ArchiveClockOutlineIcon from "mdi-react/ArchiveClockOutlineIcon.js";
import CloseIcon from "mdi-react/CloseIcon.js";
import ContentSaveOutlineIcon from "mdi-react/ContentSaveOutlineIcon.js";
import {useState} from "react";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {getIsDogChartVisible, setIsDogChartVisible} from "../../ducks/user-duck.js";
import Toggle from "../util/Toggle.jsx";
import {postSushiLogEntry} from "./_use-dawg-data.js";

export default () => {

    const dispatch = useDispatch()
    const isDogChartVisible = useSelector(getIsDogChartVisible)

    const [isFormVisible, setIsFormVisible] = useState(false)
    const [action, setAction] = useState()
    const [location, setLocation] = useState()
    const [datetime, setDatetime] = useState(dayjs().format('YYYY-MM-DDTHH:mm'))
    const [comment, setComment] = useState('')
    const [isPosting, setIsPosting] = useState(false)
    const setDatetimeToNow = () => {
        setDatetime(dayjs().format('YYYY-MM-DDTHH:mm'))
    }

    const openForm = () => {
        setDatetimeToNow()
        setIsFormVisible(true)
    }

    const resetForm = () => {
        setIsFormVisible(false)
        setAction(undefined)
        setLocation(undefined)
        setComment("")
    }

    const submitForm = async () => {
        setIsPosting(true)
        const data = {
            action,
            location,
            datetime,
            comment,
        }
        try {
            await postSushiLogEntry(data)
        } catch {

        }
        setIsPosting(false)
        resetForm()
    }

    const isFormValid = !!action && !!location && !!datetime

    const actionOnChange = value => {
        setAction(value)
        if (!location) {
            if (value === ACTIONS.POOP || value === ACTIONS.PEE) {
                setLocation(LOCATIONS.OUTSIDE)
            }
            if (value === ACTIONS.FOOD) {
                setLocation(LOCATIONS.INSIDE)
            }
        }
    }

    const toggleChart = () => {
        dispatch(setIsDogChartVisible(!isDogChartVisible))
    }

    return (
        <>
            {!isFormVisible &&
            <div className='flex-vertical-center'>
                <Button
                    icon={<PlusIcon/>}
                    onClick={openForm}
                >
                    New log entry
                </Button>
                <Toggle isChecked={isDogChartVisible} onChange={toggleChart}>
                    Chart {isDogChartVisible ? 'visible' : 'hidden'}
                </Toggle>
            </div>
            }
            {isFormVisible &&
            <div className={'form'}>
                <h3>
                    Add new log entry
                </h3>
                <section className='action'>
                    {Object.keys(ACTIONS).filter(action => action !== ACTIONS.CLOCK).map(item =>
                        <Button
                            key={item}
                            icon={<ActionIcon action={item}/>}
                            onClick={() => actionOnChange(item)}
                            isChecked={item === action}
                        >
                            {item}
                        </Button>
                    )}
                </section>
                {action &&
                <section className='location'>
                    {Object.keys(LOCATIONS).map(item =>
                        <Button
                            key={item}
                            icon={<LocationIcon location={item}/>}
                            onClick={() => setLocation(item)}
                            isChecked={item === location}
                        >
                            {item}
                        </Button>
                    )}
                </section>
                }
                {location &&
                <>
                    <section className='datetime flex-vertical-center'>
                        <input
                            id="party"
                            type="datetime-local"
                            value={datetime}
                            onChange={(e) => setDatetime(e.target.value)}
                        />
                        <Button
                            icon={<ArchiveClockOutlineIcon/>}
                            className='now-button'
                            onClick={setDatetimeToNow}
                        >

                        </Button>
                    </section>
                    <section>
                        <textarea
                            placeholder={'comment (optional)'}
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                        />
                    </section>
                </>
                }
                <section className='confirmation flex-vertical-center'>
                    <Button
                        icon={<CloseIcon/>}
                        onClick={resetForm}
                        disabled={isPosting}
                        className={'cancel-button'}
                    >
                        Cancel
                    </Button>
                    <Button
                        icon={<ContentSaveOutlineIcon/>}
                        disabled={!isFormValid || isPosting}
                        onClick={submitForm}
                    >
                        Save
                    </Button>
                </section>
            </div>
            }
        </>
    )
}