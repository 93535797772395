import {createSlice} from "@reduxjs/toolkit";

export const NAME = 'rates';

let _rates = {}

const initialState = {
    rates: {},
    date: undefined,
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setRates: (state, {payload: rates}) => {
            _rates = {
                ...rates,
            }
            state.date = new Date()
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    setRates,
} = actions

const getState = state => state[NAME]

export const getDate = (state) => getState(state).date
export const getRates = () => _rates

export default reducer