import './TaxReport.scss'
import {getTaxReportTransactions} from "../../ducks/transactions-duck.js";
import {useDispatch, useSelector} from "react-redux";
import {calculateTaxEvents} from "./_helpers.js";
import TaxYear from "./TaxYear.jsx";
import {getDustThreshold, getIsDustFiltered, setIsDustFiltered} from "../../ducks/assets-duck.js";
import Card from "../util/Card.jsx";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon.js";
import useLazyListLoader from "../util/lasy-loader/useLazyListLoader.js";
import Toggle from "../util/Toggle.jsx";
import {useState} from "react";
import {numberFormat} from "../../util/numbers.js";

export default () => {

    const dispatch = useDispatch()

    const transactions = useSelector(getTaxReportTransactions)
    const isDustFiltered = useSelector(getIsDustFiltered)
    const dustThreshold = useSelector(getDustThreshold)

    const taxYears = calculateTaxEvents(transactions)
    const {visibleList, ListLoaderComponent} = useLazyListLoader(taxYears, 2, 1)
    const [isDebtReductionActive, setIsDebtReductionActive] = useState(true)

    return (
        <div className='tax-report'>
            {isDustFiltered &&
            <Card isNegative={true} className='flex-vertical-center'>
                <AlertCircleOutlineIcon className='dull' /> «Dust» assets not included
            </Card>
            }
            <div className='options flex-vertical-center'>
                <Card>
                    <Toggle
                        isChecked={!isDustFiltered}
                        onChange={() => dispatch(setIsDustFiltered(!isDustFiltered))}
                    >
                        Include dust assets <span className='dull'> value &lt; kr {numberFormat(dustThreshold)}</span>
                    </Toggle>
                </Card>
                <Card>
                    <Toggle
                        isChecked={isDebtReductionActive}
                        onChange={() => setIsDebtReductionActive(!isDebtReductionActive)}
                    >
                        Debt reduction <span className='dull'>DAI</span>
                    </Toggle>
                </Card>
            </div>
            <table>
                <tbody>
                {visibleList.map(year =>
                    <TaxYear
                        key={year.year}
                        isDebtReductionActive={isDebtReductionActive}
                        {...year}
                    />
                )}
                </tbody>
            </table>
            <ListLoaderComponent />
        </div>
    )
}