import {useAssetSelection} from "./useAssetSelection.js";
import {useState} from "react";
import {applyFilter, FILTER_KEY_ALL, FILTER_PRESET_NAMES} from "../../../ducks/assets-duck.js";
import orderBy from "lodash/orderBy.js";
import Card from "../Card.jsx";
import Button from "../Button.jsx";
import CloseIcon from "mdi-react/CloseIcon.js";
import AssetOption from "./AssetOption.jsx";
import {LoaderWrapperHorizontal} from "../loaders/LoaderWrapper.jsx";
import './AssetSelector.scss'
import AssetIcon from "../icons/AssetIcon.jsx";
import SearchIcon from "mdi-react/SearchIcon.js";
import {applyTextFilter} from "../../../util/filtering.js";

export default ({
                    selectedAssetId,
                    selectAsset = assetId => console.log({assetId}),
                    headerSelected = 'Selected asset',
                    filterPreset,
                    isScrollable = false,
                }) => {

    const {assets, isLoading} = useAssetSelection()
    const asset = assets.find(asset => asset.id === selectedAssetId)

    const [filter, setFilter] = useState(filterPreset || FILTER_KEY_ALL)
    const [query, setQuery] = useState('')
    const assetsFiltered = applyFilter(filter, assets)

    const assetsQueryFiltered = applyTextFilter(
        assetsFiltered,
        query,
        asset => `${asset.name}${asset.code}`
    )

    const assetsWeighted = orderBy(assetsQueryFiltered.filter(asset => asset.weight > 0), ['weight'], ['desc'])

    const topAssets = assetsWeighted.slice(0, 5)

    const assetsAlphabetically = orderBy(assetsQueryFiltered, ['name'], ['asc'])

    return (
        <Card className={`asset-picker`}>
            <LoaderWrapperHorizontal isLoading={isLoading}>
                {selectedAssetId ?
                    <div className='selected-asset'>
                        <Button onClick={() => selectAsset()}>
                            <CloseIcon/>
                        </Button>
                        <div className='asset-info'>
                            <h3>
                                {headerSelected}
                            </h3>
                            <div className='asset-name'>
                                <span className='name'>{asset?.name}</span>
                                <span className='code'>{asset?.code}</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={`asset-options ${isScrollable ? 'is-scrollable' : ''}`}>
                        <div className='filters flex-vertical-center'>
                            <div className='asset-group-filter'>
                                {Object.entries(FILTER_PRESET_NAMES).map(preset => {
                                    const [key, value] = preset
                                    return (
                                        <button
                                            key={key}
                                            onClick={() => setFilter(key === filter ? undefined : key)}
                                            className={`flex-vertical-center ${filter === key && 'is-selected'}`}
                                        >
                                            <AssetIcon filterPreset={key}/>
                                            <span className='label'>
                                            {value}
                                        </span>
                                        </button>
                                    )
                                })}
                            </div>
                            <div className='text-filter hide-portrait'>
                                <SearchIcon className='search-icon'/>
                                <input
                                    type='text'
                                    onKeyDown={e => e.key === 'Escape' && setQuery('')}
                                    onChange={e => setQuery(e.target.value)}
                                    value={query}
                                />
                                {!!query &&
                                <button onClick={() => setQuery('')} className='reset-button'>
                                    <CloseIcon/>
                                </button>
                                }
                            </div>
                        </div>
                        <div className='assets'>
                            {!query && topAssets.map(asset =>
                                <AssetOption
                                    key={asset.id}
                                    selectAsset={selectAsset}
                                    isTopAsset={true}
                                    {...asset}
                                />
                            )}
                            {assetsAlphabetically.map(asset =>
                                <AssetOption
                                    key={asset.id}
                                    selectAsset={selectAsset}
                                    {...asset}
                                />
                            )}
                            {assetsAlphabetically.length === 0 &&
                            <div className={`no-hits`}>
                                No assets match query...
                            </div>
                            }
                        </div>
                    </div>
                }

            </LoaderWrapperHorizontal>
        </Card>
    )
}