import {useDispatch, useSelector} from "react-redux";
import {getAssets} from "../../../ducks/assets-duck.js";
import ExternalLink from "../../util/ExternalLink.jsx";
import './AssetUrls.scss'
import Button from "../../util/Button.jsx";
import LinkIcon from "mdi-react/LinkIcon.js";
import LinkPlusIcon from "mdi-react/LinkPlusIcon.js";
import {activateWidePopup} from "../../../ducks/popup-duck.js";
import AssetUrlAdd from "./AssetUrlAdd.jsx";

export default () => {

    const dispatch = useDispatch()
    const assets = useSelector(getAssets)

    if (assets.length > 1) {
        return null
    }

    const [asset] = assets || {}
    const {urls, id} = asset

    const urlArray = urls?.split(',')

    return (
        <div className='asset-urls'>
            {urlArray?.length > 0 && urlArray.map(url => <Url key={url} url={url}/>)}
            <Button className='new-link-button'
                    onClick={() => dispatch(activateWidePopup(<AssetUrlAdd assetId={id}/>))}>
                <LinkPlusIcon className='std-icon'/>
            </Button>
        </div>
    )
}

const Url = ({url}) => {
    const label = url.replaceAll(/http:\/\/|https:\/\/|www\./g, '').split('/')[0]
    return (
        <ExternalLink href={url}>
            <div className='flex-vertical-center'>
                <LinkIcon className='std-icon'/>
                <div>
                    {label}
                </div>
            </div>
        </ExternalLink>
    )
}