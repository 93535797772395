import './AssetList.scss'
import {useSelector} from "react-redux";
import {
    getAssets,
    getComparativeAssetCode,
    getComparativeAssetRate,
    getSortIsAsc,
    getSortProp,
    setSortIsAsc,
    setSortProp
} from "../../ducks/assets-duck.js";
import {DECIMAL_FORMAT, numberFormat, sumProps} from "../../util/numbers.js";
import Counter from "../util/Counter.jsx";
import DirectionIcon from "../util/icons/DirectionIcon.jsx";
import {APP_ROUTES} from "../../App.jsx";
import Table from "../util/table/Table.jsx";
import ThSortToggler from "../util/table/ThSortToggler.jsx";
import {scrollToTop, useNavigation} from "../util/navigation/navigation.js";
import {getUpdateKey} from "../../ducks/chart-duck.js";
import InternalLink from "../util/navigation/InternalLink.jsx";
import PlusIcon from "mdi-react/PlusIcon.js";
import {getIsHistoryFullyLoaded} from "../../ducks/history-duck.js";
import ChangeFlavorIcon from "../util/icons/ChangeFlavorIcon.jsx";
import {getDirectionColorClassName} from "../../util/misc.js";
import {getSubPathCache} from "../../ducks/user-duck.js";
import orderBy from "lodash/orderBy.js";
import dayjs from "dayjs";

const TH = ({children, sortProp, sortProps, className, isNumeric}) => {

    const currentSortProp = useSelector(getSortProp)
    const currentSortIsAsc = useSelector(getSortIsAsc)

    return (
        <ThSortToggler
            className={className}
            sortProp={sortProp}
            sortProps={sortProps}
            currentSortProp={currentSortProp}
            currentSortIsAsc={currentSortIsAsc}
            toggleSortAction={setSortProp}
            toggleIsAscAction={setSortIsAsc}
            isNumeric={isNumeric}
        >
            {children}
        </ThSortToggler>
    )
}

export default () => {

    const assets = useSelector(getAssets)
    const comparativeAssetCode = useSelector(getComparativeAssetCode)
    const updateKey = useSelector(getUpdateKey)
    const currentSortProp = useSelector(getSortProp)
    const currentSortIsAsc = useSelector(getSortIsAsc)
    const comparativeAssetRate = useSelector(getComparativeAssetRate)

    const assetsOrdered = orderBy(
        assets,
        ['isMostRecentUpdate', currentSortProp, 'value'],
        ['desc', currentSortIsAsc ? 'asc' : 'desc', 'desc']
    )

    const {value} = sumProps(assets, ['value', 'gain', 'change'])

    return (
        <div className='asset-list' key={updateKey}>
            {assets.length > 0 &&
            <>
                <Table>
                    <thead>
                    <tr>
                        <th className='hide-portrait'/>
                        <TH
                            sortProps={['name']}
                        >
                            Name
                        </TH>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['currentRate']}
                        >
                            Rate
                        </TH>
                        <th className='numeric hide-portrait'>
                            {comparativeAssetCode}
                        </th>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['value']}
                        >
                            Value
                        </TH>
                        <TH
                            isNumeric={true}
                            className='hide-portrait'
                            sortProps={['gain', 'gainPct']}
                        >
                            Return
                        </TH>
                        <TH
                            isNumeric={true}
                            sortProps={['change', 'changePct', 'changePctAbs']}
                        >
                            Change
                        </TH>
                        <th className='hide-portrait'/>
                    </tr>
                    </thead>
                    <tbody>
                    {assetsOrdered.map(asset =>
                        <AssetRow
                            key={asset.id}
                            {...asset}
                        />
                    )}
                    </tbody>
                    {assets.length > 0 &&
                    <tfoot>
                    <tr className='hide-portrait'>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td className='numeric dull'>
                            <Counter
                                amount={value / comparativeAssetRate}
                                decimals={0}
                                absolute={true}
                            />
                        </td>
                        <td className='numeric'>
                        </td>
                        <td className='numeric'>
                        </td>
                        <td className='numeric'>
                        </td>
                        <td>
                        </td>
                    </tr>
                    </tfoot>
                    }
                </Table>
            </>
            }
            {assets.length === 0 &&
            <div className='options-bar'>
                <InternalLink
                    to={APP_ROUTES.transactionsAdd}
                    isButton={true}
                    icon={<PlusIcon/>}
                >
                    add asset
                </InternalLink>
            </div>
            }
        </div>
    )
}

const AssetRow = ({
                      id,
                      name,
                      code,
                      value,
                      is_usd_asset,
                      rateUsdAth,
                      currentRate,
                      currentRateDate,
                      rateAth,
                      currentRateUsd,
                      changePct,
                      gain,
                      gainPct,
                      superGainPct,
                      isMostRecentUpdate,
                  }) => {

    const comparativeAssetRate = useSelector(getComparativeAssetRate)
    const isHistoryFullLoaded = useSelector(getIsHistoryFullyLoaded)
    const subPathCache = useSelector(getSubPathCache)

    const prevVale = value / (1 + changePct)
    const latestGainChange = value - prevVale

    const isAth = is_usd_asset ?
        currentRateUsd > rateUsdAth * .995
        :
        currentRate > rateAth * .995

    const route = subPathCache ? APP_ROUTES.history.replace('history', subPathCache) : APP_ROUTES.history
    const {navigateTo} = useNavigation(scrollToTop)

    const isMultiColored = id !== 131 // no multicolor for Nortuna
    const changeClassName = getDirectionColorClassName(changePct, isMultiColored)

    const secondsSinceRate = dayjs().diff(dayjs(currentRateDate))
    const daysSinceRate = Math.floor(secondsSinceRate / (60 * 60 * 24 * 1000))

    return (
        <tr onClick={() => navigateTo(route, {filterId: id})}>
            <td className='fade-left hide-portrait'/>
            <td className='asset-name'>
                <div className='flex-vertical-center'>
                    <span className='name'>
                        {name}
                    </span>
                    {isMultiColored &&
                    <ChangeFlavorIcon amount={changePct}/>
                    }
                    <span className='code'>
                        {code}
                    </span>
                </div>
                <div className='small hide-desktop'>
                    <div className='flex-vertical-center rate'>
                        {daysSinceRate > 0 &&
                        <div className='rate-freshness'>
                            {numberFormat(daysSinceRate, 0)}
                        </div>
                        }
                        <Counter
                            amount={is_usd_asset ? currentRateUsd : currentRate}
                            decimals={DECIMAL_FORMAT.FLEX_DEC}
                            prefix={is_usd_asset ? '$ ' : 'kr '}
                            className={isAth && isHistoryFullLoaded ? 'is-golden' : ''}
                        />
                    </div>
                </div>
            </td>
            <td className='numeric hide-portrait rate'>
                <div className='flex-vertical-center'>
                    {daysSinceRate > 0 &&
                    <div className='rate-freshness'>
                        {numberFormat(daysSinceRate, 0)}
                    </div>
                    }
                    <Counter
                        amount={is_usd_asset ? currentRateUsd : currentRate}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        prefix={is_usd_asset ? '$ ' : 'kr '}
                        className={isAth && isHistoryFullLoaded ? 'is-golden' : ''}
                    />
                </div>
            </td>
            <td className='numeric hide-portrait'>
                <Counter
                    amount={value / comparativeAssetRate}
                />
            </td>
            <td className='numeric hide-portrait'>
                <Counter
                    amount={value}
                />
            </td>
            <td className={`numeric hide-portrait`}>
                <DirectionIcon value={gain}/>
                <Counter
                    amount={gain}
                    absolute={true}
                />
                <div
                    className={`small ${gainPct > 0 ? 'is-positive' : 'is-negative'} ${superGainPct > 0 ? 'is-golden' : ''}`}>
                    <Counter
                        amount={(superGainPct || gainPct) * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
            </td>
            <td className={`numeric ${!isMostRecentUpdate && 'old-rate'}`}>
                <DirectionIcon
                    value={changePct}
                    isMultiColored={isMultiColored}
                />
                <Counter
                    amount={latestGainChange}
                    decimals={0}
                    absolute={true}
                />
                <div className={`small ${changeClassName}`}>
                    <Counter
                        amount={changePct * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
            </td>
            <td className='fade-right hide-portrait'/>
        </tr>
    )
}