import './Barometer.scss'
import {
    getAssetsFilterPreset,
    getDustThreshold, getIsBreadcrumbsVisible,
    getIsDustFiltered,
    getPercentForBarometerGraphic
} from "../../ducks/assets-duck.js";
import {useSelector} from "react-redux";
import Counter from "../util/Counter.jsx";
import ArrowUpThickIcon from "mdi-react/ArrowUpThickIcon.js";
import ArrowDownThickIcon from "mdi-react/ArrowDownThickIcon.js";
import orderBy from "lodash/orderBy.js";

export default () => {

    let assets = useSelector(getAssetsFilterPreset)
    const dustThreshold = useSelector(getDustThreshold)
    const isDustFiltered = useSelector(getIsDustFiltered)
    const percentForBarometerGraphic = useSelector(getPercentForBarometerGraphic)
    const isVisible = useSelector(getIsBreadcrumbsVisible)

    if (isDustFiltered) {
        assets = assets.filter(asset => Math.abs(asset.value) > dustThreshold && asset.id !== 104)
    }

    const assetsWithNewRate = assets.filter(asset => asset.isMostRecentUpdate && asset.changePct)
    const assetsWithOldRate = assets.filter(asset => !asset.isMostRecentUpdate && asset.changePct)

    const relevantAssets = assetsWithNewRate.length > 0 ? assetsWithNewRate : assetsWithOldRate

    const {changePctMax, changePctMin, gains, losses} = getChangeSpan(relevantAssets)

    const positiveParam = percentForBarometerGraphic ? changePctMax : gains
    const negativeParam = percentForBarometerGraphic ? changePctMin : losses

    const span = positiveParam - negativeParam
    let positivePct = Math.abs(positiveParam / span) * 100
    let negativePct = 100 - positivePct

    const threshold = 9

    if(negativePct < threshold && negativePct > 0){
        negativePct = threshold
        positivePct = 100 - threshold
    }

    if(positivePct < threshold && positivePct > 0){
        positivePct = threshold
        negativePct = 100 - threshold
    }

    const isHidden = assets.length <= 1

    if (negativeParam > 0) {
        positivePct = 100
        negativePct = 0
    } else if (positiveParam < 0) {
        positivePct = 0
        negativePct = 100
    }

    if (isHidden) {
        positivePct = negativePct = 50
    }

    return isVisible && (
        <div className={`barometer ${isHidden ? 'is-hidden' : ''}`}>
            <div className='bar'>
                <div className={`negative part ${negativePct === 100 ? 'is-complete' : ''}`} style={{"--width": `${negativePct}%`}}/>
                <div className={`positive part ${positivePct === 100 ? 'is-complete' : ''}`} style={{"--width": `${positivePct}%`}}/>
            </div>
            <div className={`value min ${changePctMin >= 0 ? 'is-positive' : 'is-negative'}`}>
                {changePctMin < 0 ? <ArrowDownThickIcon className={negativePct < 10 ? 'weak' : ''} /> : <ArrowUpThickIcon />}
                <Counter
                    amount={changePctMin * 100}
                    decimals={2}
                    suffix='%'
                    noColor={true}
                    absolute={true}
                />
            </div>
            <div className={`value max ${changePctMax >= 0 ? 'is-positive' : 'is-negative'}`}>
                {changePctMax >= 0 ? <ArrowUpThickIcon className={positivePct < 10 ? 'weak' : ''} /> : <ArrowDownThickIcon />}
                <Counter
                    amount={changePctMax * 100}
                    decimals={2}
                    suffix='%'
                    noColor={true}
                    absolute={true}
                />
            </div>
        </div>
    )
}

const getChangeSpan = (assets = []) => {
    if (assets.length === 0) {
        return [0, 0]
    }
    const assetsOrdered = orderBy(assets, ['changePct'],['desc'])
    const {gains, losses} = assets.reduce((acc, cur) => ({
        gains: acc.gains + (cur.change > 0 ? cur.change : 0),
        losses: acc.losses + (cur.change < 0 ? cur.change : 0),
    }), {
        gains: 0,
        losses: 0,
    })
    return {
        gains,
        losses,
        changePctMax: assetsOrdered[0].changePct,
        changePctMin: assetsOrdered[assetsOrdered.length - 1].changePct,
    }
}