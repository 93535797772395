export const createPortfolioAggregateDateMap = transactions => {
    const dateMap = {}
    let prevDate
    for (const transaction of transactions) {
        const {date, fund_id, cost, amount} = transaction
        if (!dateMap[date]) {
            if (!prevDate) {
                dateMap[date] = {}
            } else {
                dateMap[date] = {...dateMap[prevDate]}
            }
        }
        if (!dateMap[date][fund_id]) {
            dateMap[date][fund_id] = {
                cost: 0,
                amount: 0,
            }
        }

        dateMap[date][fund_id] = {
            cost: dateMap[date][fund_id].cost + cost,
            amount: dateMap[date][fund_id].amount + amount,
        }

        if (date !== prevDate) {
            prevDate = date
        }
    }
    return dateMap
}