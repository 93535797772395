import {useQuery} from "@tanstack/react-query";
import {MIN} from "../../util/time-and-date.js";
import {get} from "../../api/_fetch.js";
import {API_ROUTE_CRYPTO_BALANCE} from "../../../../back-end/constants/API-ROUTES.js";

export const getCryptoBalances = () => get(API_ROUTE_CRYPTO_BALANCE)

export const useCryptoBalances = () => {

    const {data = [], isLoading} = useQuery({
        queryKey: ['crypto-balances'],
        queryFn: getCryptoBalances,
        refetchInterval: 5 * MIN,
    })

    const balancesGroupedByAddress = data.reduce((acc, cur) => {
        if(!acc[cur.address]){
            acc[cur.address] = { assets: [], totalValue:0, }
        }
        return {
            ...acc,
            [cur.address]: {
                comment: cur.comment,
                location: cur.location,
                address: cur.address,
                isActive: cur.is_active === 1,
                totalValue: acc[cur.address].totalValue + cur.value,
                assets: [
                    ...acc[cur.address].assets,
                    {
                        code: cur.code,
                        name: cur.name,
                        assetId: cur.asset_id,
                        tokenAddress: cur.token_address,
                        value: cur.value,
                        balance: cur.balance,
                    }
                ]
            }
        }
    }, {})

    return {
        isLoading,
        data,
        accounts: Object.values(balancesGroupedByAddress),
    }
}