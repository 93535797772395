import dayjs from "dayjs";
import './DateDiffHighlighter.scss'

export default ({date}) => {

    const current = dayjs().format('YYYY-MM-DD')

    if(!date){
        return current
    }

    const [currentYear, currentMonth, currentDate] = current.split('-')
    const [hoverYear, hoverMonth, hoverDate] = date.split('-')

    const isYearDiff = hoverYear !== currentYear
    const isMonthDiff = hoverMonth !== currentMonth || isYearDiff
    const isDateDiff = hoverDate !== currentDate || isMonthDiff || currentDate === hoverDate

    return (
        <div className='date-diff-highlighter'>
            <span className={isYearDiff ? 'is-diff' : 'is-match'}>
                {hoverYear}
            </span>
            -
            <span className={isMonthDiff ? 'is-diff' : 'is-match'}>
                {hoverMonth}
            </span>
            -
            <span className={isDateDiff ? 'is-diff' : 'is-match'}>
                {hoverDate}
            </span>
        </div>
    )
}