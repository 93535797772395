import {createSlice} from "@reduxjs/toolkit";
import {
    getStorage,
    getTokenLocalStorage,
    removeTokenLocalStorage,
    setStorage,
    setTokenLocalStorage
} from "../api/_token.js";

export const NAME = 'user';

const initialState = {
    token: getTokenLocalStorage() || "",
    subPathCache: getStorage('sub-path-cache', 'history'),
    userId: null,
    isDogChartVisible: getStorage('is-dog-chart-visible', false),
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setToken: (state, {payload}) => {
            state.token = payload
            setTokenLocalStorage(payload)
        },
        setUserId: (state, {payload}) => {
            state.userId = payload
        },
        setIsDogChartVisible: (state, {payload}) => {
            setStorage('is-dog-chart-visible', payload)
            state.isDogChartVisible = payload
        },
        setSubPathCache: (state, {payload}) => {
            setStorage('sub-path-cache', payload)
            state.subPathCache = payload
        },
        logout: (state) => {
            state.token = ""
            removeTokenLocalStorage()
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    setToken,
    setUserId,
    setSubPathCache,
    setIsDogChartVisible,
    logout,
} = actions

const getState = state => state[NAME]

export const getToken = state => getState(state).token
export const getIsLoggedIn = state => !!getToken(state)
export const getUserId = state => getState(state).userId
export const getIsDogChartVisible = state => getState(state).isDogChartVisible
export const getSubPathCache = state => getState(state).subPathCache
export const getIsOddy = state => getUserId(state) === 1
export const getIsWinnie = state => getUserId(state) === 6

export default reducer