import './Toggle.scss'

export default ({
                    isChecked = false,
                    isLightTheme = false,
                    onChange = console.log,
                    children,
                }) => {
    return (
        <div className={`toggle ${isChecked ? 'is-checked' : ''} ${isLightTheme ? 'light-theme' : 'dark-theme'}`} onClick={() => onChange(!isChecked)}>
            <button
                className='switch'
            />
            {!!children &&
            <div className='label'>
                {children}
            </div>
            }
        </div>
    )
}