import PlusIcon from "mdi-react/PlusIcon.js";
import {ACTIONS} from "./_helpers.js";
import FacePoopIcon from "mdi-react/FacePoopIcon.js";
import FoodDrumstickOutlineIcon from "mdi-react/FoodDrumstickOutlineIcon.js";
import WaterOutlineIcon from "mdi-react/WaterOutlineIcon.js";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon.js";

export default ({action, className = ''}) => {
    switch (action) {
        case ACTIONS.POOP:
            return <FacePoopIcon className={className}/>
        case ACTIONS.PEE:
            return <WaterOutlineIcon className={className}/>
        case ACTIONS.FOOD:
            return <FoodDrumstickOutlineIcon className={className}/>
        case ACTIONS.CLOCK:
            return <ClockOutlineIcon className={className}/>
        default:
            return <PlusIcon className={className}/>
    }
}