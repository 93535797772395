export const createHistory = (assets, ratesMap, portfolioAggregateDateMap) => {
    const assetIds = assets.map(asset => asset.id)
    let history = {}
    let historyYesterday = {}
    let portfolioYesterday = {}
    let ratesYesterday = {}
    const dates = Object.keys(ratesMap)
    for (const date of dates) {
        const rates = ratesMap[date]
        const portfolio = portfolioAggregateDateMap[date] || portfolioYesterday || {}
        history[date] = {}
        for (const assetId of assetIds) {
            const rateYesterday = ratesYesterday[assetId]?.r || 0
            const gainYesterday = historyYesterday[assetId]?.gain || 0
            const valueYesterday = historyYesterday[assetId]?.value || 0
            const amount = portfolio[assetId]?.amount || 0
            const cost = portfolio[assetId]?.cost || 0

            const rate = rates[assetId]?.r || historyYesterday[assetId]?.rate || null
            const usdRate = rates[assetId]?.usd || ratesYesterday[assetId]?.usdRate || null
            const rateChange = !rateYesterday ? 0 : rate - rateYesterday
            const rateChangePct = !rateYesterday ? 0 : rateChange / rateYesterday

            const value = rate * amount
            const gain = value - cost
            const gainChange = !gainYesterday ? 0 : gain - gainYesterday
            const gainChangePercent = !valueYesterday ? 0 : gainChange / valueYesterday

            history[date][assetId] = {
                rate,
                usdRate,
                cost,
                amount,
                rateChange,
                rateChangePct,
                value,
                valueYesterday,
                gain,
                gainChange,
                gainChangePercent,
            }
        }
        portfolioYesterday = portfolio
        ratesYesterday = rates
        historyYesterday = history[date]
    }
    return history
}