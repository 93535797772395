import './Analysis.scss'
import {useAnalysis} from "./useAnalysis.js";
import Counter from "../util/Counter.jsx";
import Card from "../util/Card.jsx";
import {LoaderWrapperCircle} from "../util/loaders/LoaderWrapper.jsx";
import {DECIMAL_FORMAT} from "../../util/numbers.js";


const PERIODS = [
    {
        label: 'week',
        days: 7,
    },
    {
        label: 'month',
        days: 31,
    },
    {
        label: '3 months',
        days: 92,
    },
    {
        label: '6 months',
        days: 181,
    },
    {
        label: 'year',
        days: 365,
    },
    {
        label: '3 years',
        days: 365 * 3 + 1,
    },
    {
        label: '5 years',
        days: 365 * 5 + 2,
    },
    {
        label: '8 years',
        days: 365 * 8 + 3,
    },
    {
        label: '10 years',
        days: 365 * 10 + 3,
    },
]

const DURATIONS = PERIODS.map(period => period.days)

export default () => {

    const {getValuesOnIndex, isLoading} = useAnalysis()

    const getComparativeData = (duration) => {
        const base = getValuesOnIndex(0)
        const comp = getValuesOnIndex(duration)
        const valueChange = base.value - comp.value
        const gainChange = base.gain - comp.gain
        const gainValueDiff = valueChange - gainChange
        return {
            valueChange,
            gainChange,
            gainValueDiff,
            valueChangePct: valueChange / base.value * 100,
            gainChangePct: gainChange / base.value * 100,
            gainValueDiffPct: gainValueDiff / base.value * 100,
        }
    }

    return (
        <div className='analysis'>
            <LoaderWrapperCircle isLoading={isLoading}>
                <div className='kpis'>
                    {DURATIONS.map(duration =>
                        <Duration
                            key={duration}
                            duration={duration}
                            {...getComparativeData(duration)}
                        />
                    )}
                </div>
            </LoaderWrapperCircle>
        </div>
    )
}

const Duration = ({
                      duration,
                      valueChange,
                      valueChangePct,
                      gainChange,
                      gainChangePct,
                      gainValueDiff,
                      gainValueDiffPct,
                  }) => {
    const label = PERIODS.find(period => period.days === duration).label
    return (
        <Card className='duration'>
            <h2>{label}</h2>
            <div className='kpi'>
                <h3>
                    value diff
                </h3>
                <div className='values'>
                    <Counter
                        amount={valueChange}
                    />
                    <Counter
                        className='annualized'
                        amount={valueChange / duration * 365}
                    />
                </div>
            </div>
            <div className='kpi-pct'>
                <div className='values'>
                    <Counter
                        amount={valueChangePct}
                        suffix='%'
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                    />
                    <Counter
                        className='annualized'
                        suffix='%'
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        amount={valueChangePct / duration * 365}
                    />
                </div>
            </div>
            <div className='kpi'>
                <h3>
                    return diff
                </h3>
                <div className='values'>
                    <Counter
                        amount={gainChange}
                    />
                    <Counter
                        className='annualized'
                        amount={gainChange / duration * 365}
                    />
                </div>
            </div>
            <div className='kpi-pct'>
                <div className='values'>
                    <Counter
                        amount={gainChangePct}
                        suffix='%'
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                    />
                    <Counter
                        className='annualized'
                        suffix='%'
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        amount={gainChangePct / duration * 365}
                    />
                </div>
            </div>
            <div className='kpi'>
                <h3>
                    net flow
                </h3>
                <div className='values'>
                    <Counter amount={gainValueDiff}/>
                    <Counter
                        className='annualized'
                        amount={gainValueDiff / duration * 365}
                    />
                </div>
            </div>
            <div className='kpi-pct'>
                <div className='values'>
                    <Counter
                        amount={gainValueDiffPct}
                        suffix='%'
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                    />
                    <Counter
                        className='annualized'
                        suffix='%'
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        amount={gainValueDiffPct / duration * 365}
                    />
                </div>
            </div>
        </Card>
    )
}