import {useSelector} from "react-redux";
import {getHistoryArray} from "../../ducks/history-duck.js";
import {getAssetIds} from "../../ducks/assets-duck.js";

export const useAnalysis = () => {

    const history = useSelector(getHistoryArray)
    const assetIds = useSelector(getAssetIds)

    const getValuesOnIndex = idx => {
        const idxReversed = Math.max(0, history.length - 1 - idx)
        return assetIds.reduce((acc,cur) => ({
            value: acc.value + history[idxReversed][1][cur]?.value,
            gain: acc.gain + history[idxReversed][1][cur]?.gain,
        }), {
            gain: 0,
            value: 0,
        })
    }

    return {
        getValuesOnIndex,
        isLoading: history.length < 30,
    }
}