import React from 'react';

import './LoaderHorizontal.scss';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

const LoaderHorizontal = () => {
    return (
        <div className='loader loader-horizontal' />
    )
};

export const HorizontalLoadWrapper = ({isLoading = false, children}) => {
    return isLoading ? <LoaderHorizontal/> : children
}

export const SkeletonWrapper = ({isLoading = false, children, ...restOfProps}) => {
    return isLoading ? (
        <SkeletonTheme color="#14162d" highlightColor="#1f2442">
            <Skeleton {...restOfProps} />
        </SkeletonTheme>
    ) : children
}

export default LoaderHorizontal;