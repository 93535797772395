import './AssetKPIs.scss'
import {useSelector} from "react-redux";
import {
    FILTER_KEY_CRYPTO,
    getAssets,
    getAssetSelectionAggregate,
    getComparativeAssetCode,
    getComparativeAssetRate,
    getCurrentAsset,
    getFilterPreset,
    getIsSingleAsset,
    getRethRate
} from "../../../ducks/assets-duck.js";
import Counter from "../../util/Counter.jsx";
import Card from "../../util/Card.jsx";
import {DECIMAL_FORMAT} from "../../../util/numbers.js";
import GainPct from "../../util/GainPct.jsx";
import {getIsOddy} from "../../../ducks/user-duck.js";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon.js";
import MinusCircleOutlineIcon from "mdi-react/MinusCircleOutlineIcon.js";
import WaterOutlineIcon from "mdi-react/WaterOutlineIcon.js";
import HorizontalLineIcon from "mdi-react/HorizontalLineIcon.js";

export default () => {

    const assets = useSelector(getAssets)
    const isSingleAsset = useSelector(getIsSingleAsset)
    const comparativeAssetCode = useSelector(getComparativeAssetCode)
    const comparativeAssetRate = useSelector(getComparativeAssetRate)

    const {
        value,
        cost,
        gain,
        days_age,
        weighted_days_age,
    } = useSelector(getAssetSelectionAggregate)

    const weightedDaysAge = weighted_days_age / value
    const gainsAnnualized = (gain / Math.min(Math.abs(weightedDaysAge), Math.abs(days_age))) * 365
    const avgAssetValue = value / assets.length
    const avgAssetReturn = gain / assets.length

    return (
        <>
            <div className='asset-kpis'>
                <KPI
                    header={'Returns annualized'}
                    className='returns-annualized'
                >
                    <WaterOutlineIcon className='is-golden'/>
                    {cost === 0 ?
                        <HorizontalLineIcon style={{opacity: '100%'}}/> :
                        <>
                            <Counter
                                amount={gainsAnnualized}
                                decimals={DECIMAL_FORMAT.FLEX_DEC}
                                suffix={''}
                                absolute={true}
                            />
                            <GainPct
                                className='secondary'
                                value={value + gainsAnnualized}
                                gain={gainsAnnualized}
                                decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                            />
                        </>
                    }
                </KPI>
                <KPI
                    header={'Total value'}
                    className='total-value'
                >
                    <Counter
                        amount={value}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                    />
                    <Counter
                        amount={value / comparativeAssetRate}
                        className='secondary weak'
                        suffix={` ${comparativeAssetCode}`}
                        decimals={DECIMAL_FORMAT.FLEX}
                    />
                </KPI>
                <KPI
                    header={gain < 0 ? 'Loss' : 'Return'}
                    className='return'
                >
                    <Counter
                        amount={gain}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        absolute={true}
                    />
                    <GainPct
                        className='secondary'
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        value={value}
                        gain={gain}
                    />
                </KPI>
                <KPI
                    header={cost >= 0 ? 'Net cost' : 'Realized gains'}
                    className={cost > 0 ? '' : 'golden'}
                >
                    <Counter
                        amount={cost}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        absolute={true}
                    />
                </KPI>
            </div>
            <div className='asset-kpis'>
                {isSingleAsset ?
                    <SingleAssetKPIs
                    />
                    :
                    <AssetGroupKPIs
                        avgAssetValue={avgAssetValue}
                        avgAssetReturn={avgAssetReturn}
                        totalValue={value}
                    />
                }
            </div>
        </>
    )
}

const AssetGroupKPIs = ({avgAssetValue, avgAssetReturn, totalValue}) => {
    const rethRate = useSelector(getRethRate)
    const isOddy = useSelector(getIsOddy)
    const filterPreset = useSelector(getFilterPreset)
    const target = 10_000_000
    const targetThreshold = .05
    const targetDiff = target - totalValue
    const targetDiffReth = targetDiff / rethRate
    const isDiffAboveThreshold = Math.abs(targetDiff) > target * targetThreshold
    return (
        <>
            <KPI
                header={'Avg asset value'}
            >
                <Counter
                    amount={avgAssetValue}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                />
            </KPI>
            <KPI
                header={'Avg asset return'}
            >
                <Counter
                    amount={avgAssetReturn}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                />
                <GainPct
                    className='secondary'
                    value={avgAssetValue}
                    gain={avgAssetReturn}
                />
            </KPI>
            {isOddy && FILTER_KEY_CRYPTO === filterPreset &&
            <KPI
                header={'Rebalance calc'}
                isPositive={targetDiff < 0 && isDiffAboveThreshold}
                isNegative={targetDiff > 0 && isDiffAboveThreshold}
            >
                {targetDiff > 0 ? <PlusCircleOutlineIcon/> : <MinusCircleOutlineIcon/>}
                <Counter
                    amount={targetDiff}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                    absolute={true}
                />
                <Counter
                    className='secondary weak'
                    amount={targetDiffReth}
                    decimals={2}
                    suffix={' rETH'}
                    absolute={true}
                />
            </KPI>
            }
        </>
    )
}

const calculateAnnualizedRateTrajectory = (currentRate, prevRate, duration) => {
    const diff = currentRate - prevRate
    return (diff / prevRate) * (12 / duration)
}

const SingleAssetKPIs = () => {

    const {
        currentRate,
        value,
        rate_3_mth,
        rate_6_mth,
        rate_9_mth,
        rate_12_mth,
    } = useSelector(getCurrentAsset)

    const annualizedTrajectory3mth = calculateAnnualizedRateTrajectory(currentRate, rate_3_mth, 3)
    const annualizedTrajectory6mth = calculateAnnualizedRateTrajectory(currentRate, rate_6_mth, 6)
    const annualizedTrajectory9mth = calculateAnnualizedRateTrajectory(currentRate, rate_9_mth, 9)
    const annualizedTrajectory12mth = calculateAnnualizedRateTrajectory(currentRate, rate_12_mth, 12)

    return (
        <>
            {rate_3_mth &&
            <KPI
                header={<><em>3 mth</em> trajectory</>}
            >
                <Counter
                    amount={annualizedTrajectory3mth * 100}
                    decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                    suffix={'%'}
                    className={annualizedTrajectory3mth >= 0 ? 'is-positive' : 'is-negative'}
                    absolute={true}
                />
                <Counter
                    amount={annualizedTrajectory3mth * value}
                    decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                    suffix={' kr'}
                    className={`secondary`}
                    absolute={true}
                />
            </KPI>
            }
            {rate_6_mth &&
            <KPI
                header={<><em>6 mth</em> trajectory</>}
            >
                <Counter
                    amount={annualizedTrajectory6mth * 100}
                    decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                    suffix={'%'}
                    className={annualizedTrajectory6mth >= 0 ? 'is-positive' : 'is-negative'}
                    absolute={true}
                />
                <Counter
                    amount={annualizedTrajectory6mth * value}
                    decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                    suffix={' kr'}
                    className={`secondary`}
                    absolute={true}
                />
            </KPI>
            }
            {rate_9_mth &&
            <KPI
                header={<><em>9 mth</em> trajectory</>}
            >
                <Counter
                    amount={annualizedTrajectory9mth * 100}
                    decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                    suffix={'%'}
                    className={annualizedTrajectory9mth >= 0 ? 'is-positive' : 'is-negative'}
                    absolute={true}
                />
                <Counter
                    amount={annualizedTrajectory9mth * value}
                    decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                    suffix={' kr'}
                    className={`secondary`}
                    absolute={true}
                />
            </KPI>
            }
            {rate_12_mth &&
            <KPI
                header={<><em>12 mth</em> trajectory</>}
            >
                <Counter
                    amount={annualizedTrajectory12mth * 100}
                    decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                    suffix={'%'}
                    className={annualizedTrajectory12mth >= 0 ? 'is-positive' : 'is-negative'}
                    absolute={true}
                />
                <Counter
                    amount={annualizedTrajectory12mth * value}
                    decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                    suffix={' kr'}
                    className={`secondary`}
                    absolute={true}
                />
            </KPI>
            }
        </>
    )
}

const KPI = ({
                 header = '',
                 children,
                 isVisible = true,
                 className = "",
                 isPositive,
                 isNegative,
             }) => {
    if (!isVisible) {
        return null
    }
    return (
        <Card
            className={`kpi ${className}`}
            isPositive={isPositive}
            isNegative={isNegative}
        >
            <h3>
                {header}
            </h3>
            <div className='kpi-value'>
                {children}
            </div>
        </Card>
    )
}