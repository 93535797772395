export const CURRENCIES = [
    {
        name: 'NOK',
        symbol: 'kr',
        code: 'NOK',
    },
    {
        name: 'SEK',
        symbol: 'SEK',
        code: 'X:SSEKNOK.IDCFX',
    },
    {
        name: 'USD',
        symbol: '$',
        code: 'X:SUSDNOK.IDCFX',
    },
    {
        name: 'EUR',
        symbol: '€',
        code: 'X:SEURNOK.IDCFX',
    },
]