import {useState} from 'react'

import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'

import ProgressStep from './ProgressStep'

import {useMountEffect} from "../../../util/mounting-hooks.js";
import Button from "../Button.jsx";
import WizardStep from "./WizardStep.jsx";
import './Wizard.scss'

export default ({children, initialStep = 0, isLoading = false, onComplete, onStepChange}) => {
    const [activeStep, setActiveStep] = useState(initialStep)
    const [furthestStep, setFurthestStep] = useState(initialStep)

    const wizardChildren = (
        !Array.isArray(children) ? [children] : children
    )
    const visibleChildren = wizardChildren.filter(({props}) => !props.isHidden)
    const stepCount = visibleChildren.length

    useMountEffect(() => {
        setActiveStep(initialStep)
        setFurthestStep(initialStep)
    }, [initialStep])

    const {
        children: activeStepChildren,
        previousButtonText = 'Previous step',
        skipButtonText = 'Skip',
        nextButtonText = 'Next step',
        displayNavigation = true,
        isSkippable = false,
        isFinalStep = activeStep === stepCount - 1,
        isValid,
    } = visibleChildren[activeStep]?.props || {}

    const changeStepTo = (nextStep) => {
        const nextFurthestStep = furthestStep < nextStep ? nextStep : furthestStep

        setActiveStep(nextStep)
        setFurthestStep(nextFurthestStep)
        if (onStepChange) onStepChange(nextStep, nextFurthestStep)
    }

    const advanceWizard = () => {
        const nextStep = activeStep + 1
        if (isFinalStep) {
            onComplete()
        } else {
            changeStepTo(nextStep)
        }
    }

    const regressWizard = () => {
        changeStepTo(activeStep - 1)
    }
    const Step = activeStepChildren.type || WizardStep

    return (
        <div className="ui-wizard">
            <ol className="ui-wizard__progress">
                {visibleChildren.map((step, index) => {
                    const {name, isStarted, isValid} = step.props

                    return (
                        <ProgressStep
                            key={index}
                            index={index}
                            name={name}
                            isActive={activeStep === index}
                            isValid={isValid}
                            isStarted={isStarted || index <= furthestStep}
                            isDisabled={isLoading}
                            onClick={() => changeStepTo(index)}
                        />
                    )
                })}
            </ol>

            <div className="ui-wizard__steps">
                <Step
                    advanceWizard={advanceWizard}
                    regressWizard={regressWizard}
                    onActiveStepSet={(newActiveStep) => setActiveStep(newActiveStep)}
                    {...visibleChildren[activeStep]?.props}
                />
            </div>

            {displayNavigation && (
                <div className="ui-wizard__navigation">
                    <Button
                        onClick={regressWizard}
                        disabled={activeStep === 0 || isLoading}
                        icon={<ChevronLeftIcon/>}
                    >
                        {previousButtonText}
                    </Button>
                    {isSkippable && !isFinalStep && (
                        <Button onClick={() => changeStepTo(activeStep + 1)}>{skipButtonText}</Button>
                    )}
                    <Button
                        id="ui-wizard-next-step-button"
                        disabled={!isValid || isLoading}
                        icon={<ChevronRightIcon/>}
                        isIconRight={true}
                        isLoading={isLoading}
                        onClick={advanceWizard}
                    >
                        {nextButtonText}
                    </Button>
                </div>
            )}
        </div>
    )
}
