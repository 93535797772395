import AssetIcon from "../icons/AssetIcon.jsx";
import StarOutlineIcon from "mdi-react/StarOutlineIcon.js";

export default ({id, name, asset_type, selectAsset, code, isTopAsset = false}) => {
    return (
        <div className={`asset-option ${isTopAsset ? 'is-top-asset' : ''}`}>
            <button onClick={() => selectAsset(id)}>
                {isTopAsset ?
                    <StarOutlineIcon
                        className={'std-icon'}
                    />
                    :
                    <AssetIcon
                        assetType={asset_type}
                        isColored={true}
                    />
                }
                <span className='name'>{name}</span>
                <span className='code'>{code}</span>
            </button>
        </div>
    )
}