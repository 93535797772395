import {Doughnut} from "react-chartjs-2";
import Counter from "../../util/Counter.jsx";
import './CountdownChart.scss'
import {DECIMAL_FORMAT} from "../../../util/numbers.js";

export default ({
                    amount = 0,
                    targetAmount = 1,
                    className = '',
                }) => {

    const percentage = amount / (targetAmount || 1)
    const remainingAmount = targetAmount - amount

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        rotation: -90,
        cutout: "80%",
        borderWidth: 2.4,
        animation: {
            animateScale: false,
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false,
            },
        }
    }

    const ctx = document.createElement('canvas')?.getContext("2d")
    const gradientStroke = ctx.createLinearGradient(0, 0, 100, 0);
    gradientStroke.addColorStop(0, "#f1c40f");
    gradientStroke.addColorStop(1, "#e67e22")

    const gradientStroke2 = ctx.createLinearGradient(0, 0, 100, 0);
    gradientStroke2.addColorStop(0, "#885eb2");
    gradientStroke2.addColorStop(1, "#433d79");

    const data = {
        datasets: [{
            backgroundColor: ['rgba(12, 13, 27, 0.95)', percentage >= .995 ? gradientStroke : gradientStroke2],
            borderColor: '#161616',
            borderWidth: 0,
            data: [remainingAmount, amount]
        }],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [
            'completed',
            'remaining',
        ]
    }

    return (
        <div className={'countdown-chart'}>
            <div className='canvas'>
                <Doughnut
                    options={options}
                    data={data}
                    type={null}
                />
            </div>
            <div className='percentage'>
                <Counter
                    amount={Math.min(percentage > 1.001 ? 50 : percentage * 100)}
                    decimals={DECIMAL_FORMAT.FLEX_INT}
                    suffix={'%'}
                />
            </div>
        </div>
    )
}