import LoaderHorizontal from "./LoaderHorizontal.jsx";
import LoaderCircle from "./LoaderCircle.jsx";

export const LoaderWrapperHorizontal = ({isLoading = false, children}) => {
    return isLoading ?
        <LoaderHorizontal />
        :
        children
}

export const LoaderWrapperCircle = ({isLoading = false, children}) => {
    return isLoading ?
        <LoaderCircle />
        :
        children
}