import PlusIcon from "mdi-react/PlusIcon.js";
import {LOCATIONS} from "./_helpers.js";
import HomeRoofIcon from "mdi-react/HomeRoofIcon.js";
import TableFurnitureIcon from "mdi-react/TableFurnitureIcon.js";
import NatureOutlineIcon from "mdi-react/NatureOutlineIcon.js";

export default ({location, className = ''}) => {
    switch (location) {
        case LOCATIONS.INSIDE:
            return <HomeRoofIcon className={className}/>
        case LOCATIONS.TERRACE:
            return <TableFurnitureIcon className={className}/>
        case LOCATIONS.OUTSIDE:
            return <NatureOutlineIcon className={className}/>
        default:
            return <PlusIcon className={className}/>
    }
}