import './Card.scss'

export default ({
                    className,
                    isNegative = false,
                    isPositive = false,
                    isError = false,
                    ...restOfProps
                }) => {
    return (
        <div
            className={`
                card ${className}
                ${isPositive ? 'is-positive' : ''}
                ${isNegative ? 'is-negative' : ''}
                ${isError ? 'is-error' : ''}
            `}
             {...restOfProps}
        />
    )
}