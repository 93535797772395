import './ThSortToggler.scss'
import {useDispatch} from "react-redux";
import ChevronDownIcon from "mdi-react/ChevronDownIcon.js";

export default ({
                    children,
                    className = '',
                    sortProp = "",
                    sortProps = [],
                    toggleSortAction = () => {
                    },
                    toggleIsAscAction = () => {
                    },
                    currentSortProp = "",
                    currentSortIsAsc = "",
                    isNumeric = false,
                }) => {

    const dispatch = useDispatch()

    const idx = sortProps.indexOf(currentSortProp)
    const isActive = idx !== -1 || sortProp === currentSortProp
    const arrowIndicators = Math.max(1, idx + 1)

    const onClick = () => {
        if (sortProps.length > 0) {
            if (idx === -1) {
                dispatch(toggleSortAction(sortProps[0]))
                dispatch(toggleIsAscAction(false))
            } else if (!currentSortIsAsc) {
                dispatch(toggleIsAscAction(true))
            } else {
                const nextIdx = idx === sortProps.length - 1 ? 0 : idx + 1
                dispatch(toggleSortAction(sortProps[nextIdx]))
                dispatch(toggleIsAscAction(false))
            }
        } else {
            if (currentSortProp === sortProp) {
                dispatch(toggleIsAscAction(!currentSortIsAsc))
            } else {
                dispatch(toggleSortAction(sortProp))
                dispatch(toggleIsAscAction(false))
            }
        }
    }

    return (
        <th
            className={`
                sort-toggler
                ${isActive ? 'is-active' : ''}
                ${currentSortIsAsc ? 'is-asc' : 'is-desc'}
                ${className}
            `}
            onClick={onClick}
        >
            <div className={`flex-vertical-center ${isNumeric ? 'flex-end' : ''}`}>
                {isActive && isNumeric && <Icon n={arrowIndicators} />}
                <div className={isNumeric}>
                    {children}
                </div>
                {isActive && !isNumeric && <Icon n={arrowIndicators} />}
            </div>
        </th>
    )
}

const Icon = ({n = 1}) => (
    <div className='icons'>
        {[...Array(n).keys()].map(i => <ChevronDownIcon key={i} />)}
    </div>
)