import {TOKEN_HEADER_TYPE, TOKEN_PREFIX} from "../../../back-end/constants/authorization.js";
import {StatusCodes} from "http-status-codes";
import {getTokenLocalStorage} from "./_token.js";
import {getStore} from "../App.jsx";
import {logout} from "../ducks/user-duck.js";

export const post = (path, data) => requestWithData('POST', path, data)
export const patch = (path, data) => requestWithData('PATCH', path, data)
export const put = (path, data) => requestWithData('PUT', path, data)
export const get = (path, data) => request('GET', path, data)
export const deleteReq = (path, data) => request('DELETE', path, data)

const requestWithData = async (method, path, data = {}) => request(method, path, data)

const request = async (method, path, data) => {

    const dispatch = console.log
    // const {getState, dispatch} = getStore()
    const token = getTokenLocalStorage()
    // const token = getToken(getState())
    const isTokenExpired = ""
    // const isTokenExpired = getIsTokenExpired(getState())

    if(isTokenExpired){
        // dispatch(logout())
    }

    let response = await fetch(process.env.REACT_APP_API_HOST + path, {
        method: method,
        headers: {
            ...(data && { 'Content-Type': 'application/json' }),
            ...(token && { [TOKEN_HEADER_TYPE]: formatToken(token)}),
        },
        ...(data && { body: JSON.stringify(data) }),
    });

    return handleResponse(response, dispatch)

}

const handleResponse = async (response) => {
    const {dispatch} = getStore()
    const json = await response.json()
    if(response.status === StatusCodes.UNAUTHORIZED){
        dispatch(logout())
        // dispatch(openModal(<TokenExpired />))
        return Promise.reject({ ...json, status: response.status })
    }
    return response.status !== StatusCodes.OK ? Promise.reject({ ...json, status: response.status }) : json
}

const formatToken = token => `${TOKEN_PREFIX} ${token}`