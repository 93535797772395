/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import './ProgressStep.scss'

export default ({
          index,
          name,
          isStarted = false,
          isActive = false,
          isValid = false,
          isDisabled = false,
          onClick,
      }) => {
    const startedClassName = isStarted ? 'started' : ''
    const activeClassName = isActive ? 'active' : 'inactive'
    const validityClassName = isStarted && !isValid ? 'invalid' : 'valid'
    const isStepDisabled = !isStarted || isDisabled

    return (
        <li
            className={`step ${startedClassName} ${activeClassName} ${validityClassName}`}
            onClick={() => isValid && !isStepDisabled && onClick()}
        >
            <div className="step-content">
                <div className="left-connector" />
                <button className="step-number" onClick={onClick} disabled={isStepDisabled || !isValid}>
                    {index + 1}
                </button>
                <div className="right-connector" />
            </div>
            <div className="step-name">{name || `Step ${index + 1}`}</div>
        </li>
    )
}
