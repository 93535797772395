import {useDispatch, useSelector} from "react-redux";
import {getHistoryArray, getLatestDateWithChange} from "../../ducks/history-duck.js";
import {getAssetIds} from "../../ducks/assets-duck.js";
import {
    getChartDuration,
    getChartFocus,
    getUpdateKey,
    setChartHoverDate,
    setChartHoverValues
} from "../../ducks/chart-duck.js";
import {getRelativePosition} from "chart.js/helpers";
import dayjs from "dayjs";

const getChartValues = (history, assetIds, prop) => {
    const values = []
    let maxValue = 0
    for (let i = 0; i < history.length; i++) {
        let value = 0
        for (const assetId of assetIds) {
            value += history[i][1][assetId][prop]
        }
        // ignore zero value days at start of dataset
        if (!!value || values.length > 0) {
            maxValue = Math.max(value, maxValue)
            values.push(value)
        }
    }
    return {
        values,
        maxValue
    }
}

const getStatistics = (valuesFull, maxValue) => {
    const latestValue = valuesFull[valuesFull.length - 1]
    let isLatestMovePositive

    const isATH = latestValue >= maxValue * .995
    for (let i = valuesFull.length - 2; i > 0; i--) {
        const value = valuesFull[i]
        if (value !== latestValue) {
            isLatestMovePositive = value < latestValue
            i = 0
        }
    }
    return {
        isATH,
        isLatestMovePositive,
    }
}

export const useChartData = () => {

    const dispatch = useDispatch()

    const history = useSelector(getHistoryArray)
    const assetIds = useSelector(getAssetIds)

    const chartDuration = useSelector(getChartDuration)
    const chartFocus = useSelector(getChartFocus)
    const updateKey = useSelector(getUpdateKey)
    const latestDateWithChange = useSelector(getLatestDateWithChange)

    const getPropFromDate = (prop) => (date) => assetIds.reduce((acc, cur) => acc + (date && date[1][cur] && date[1][cur][prop]), 0)

    const {values: valuesFull, maxValue} = getChartValues(history, assetIds, chartFocus)
    const {isLatestMovePositive, isATH} = getStatistics(valuesFull, maxValue)

    // ignore zero value days at start of dataset
    const historyZeroDaySlice = history.slice(history.length - valuesFull.length)

    const sliceKey = (chartDuration + 1) * -1

    const historySlicePlusOne = historyZeroDaySlice.slice(sliceKey)
    const historySlice = historySlicePlusOne.slice(sliceKey + 1)

    const labels = historySlice.map(date => date[0]).reverse()
    const values = valuesFull.slice(sliceKey + 1).reverse()

    const onMouseMove = (e, chartRef) => {
        if (!chartRef.current) {
            return
        }
        const {x} = getRelativePosition(e, chartRef.current)
        const xOffset = chartRef.current.width / 380
        const index = chartRef.current.scales.x.getValueForPixel(x + xOffset)
        const date = dayjs(index).format("YYYY-MM-DD")
        setHoverValuesFromDate(date, updateKey)
    }

    const setHoverValuesFromDate = (date, filter) => {
        const dataIndex = labels.indexOf(date)
        const hoverDate = labels[dataIndex]
        const hoverValue = historySlice[historySlice.length - dataIndex - 1]
        const hoverValuePrev = historySlicePlusOne[historySlicePlusOne.length - dataIndex - 2]
        if (dataIndex >= 0) {
            const focusValue = getPropFromDate(chartFocus)(hoverValue)
            const value = getPropFromDate('value')(hoverValue)
            const valuePrev = getPropFromDate('value')(hoverValuePrev)
            const gain = getPropFromDate('gain')(hoverValue)
            const gainPrev = getPropFromDate('gain')(hoverValuePrev)
            if (hoverDate) {
                dispatch(setChartHoverDate(hoverDate))
                dispatch(setChartHoverValues({
                    focusValue,
                    value,
                    valuePrev,
                    gain,
                    gainPrev,
                    updateKey: filter,
                }))
            }
        }
    }

    const onMouseOut = (filter) => {
        setHoverValuesFromDate(latestDateWithChange, filter)
    }

    return {
        isATH,
        isLatestMovePositive,
        values,
        labels,
        onMouseMove,
        onMouseOut,
    }

}