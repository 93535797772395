import {useQuery} from "@tanstack/react-query";
import {getAssetsApi} from "../../../api/statusApi.js";
import {SEC} from "../../../util/time-and-date.js";

export const useAssetSelection = () => {

    const {data: assets = [], isLoading} = useQuery({
        queryKey: ['assets'],
        queryFn: getAssetsApi,
        refetchInterval: 5 * SEC,
    })

    return {
        assets,
        isLoading,
    }

}