import ChartHover from "./ChartHover.jsx";
import Breadcrumbs from "./Breadcrumbs.jsx";
import {useSelector} from "react-redux";
import {getUpdateKey} from "../../ducks/chart-duck.js";
import './Dashboard.scss'
import Subnav from "./Subnav.jsx";
import Barometer from "./Barometer.jsx";
import Addendum from "./Addendum.jsx";

export default () => {
    const updateKey = useSelector(getUpdateKey)
    return (
        <>
            <div className='dashboard' id='dashboard'>
                <ChartHover key={updateKey}/>
                <Breadcrumbs />
            </div>
            <Addendum>
                <Subnav />
                <Barometer />
            </Addendum>
        </>
    )
}