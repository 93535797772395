export const getFilteredList = (state, listSelector, ...filters) => {
    let list = listSelector(state) || [];
    filters.forEach(filter => {
        list = filter(state, list)
    })
    return [...list]
};

export const applyTextFilter = (list, query, toString = elm => elm.toString()) => {

    if (!query) return list

    const keyWords = getRegexSafeString(query).split(' ')

    return list.filter(
        item => keyWords.every(
            keyWord => {
                const regExp = new RegExp(keyWord, "i")
                return toString(item).match(regExp)
            })
    );
}

export const getQueryFilter = (
    query,
    stringMatcher = item => '' + item.name,
    additionalMatching = () => false
) => {
    if (!query) return () => true
    const keyWords = getRegexSafeString(query).split(' ')

    return item => keyWords.every(keyWord =>
        stringMatcher(item).match(new RegExp(keyWord, "i"))
    ) || additionalMatching(item)
}

export const getRegexSafeString = string => string.replace(/[+()]/g, ' ')