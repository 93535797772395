import Button from "../../../util/Button.jsx";
import {useDispatch} from "react-redux";
import {setType} from "../../../../ducks/add-transaction-duck.js";
import PlusCircleOutlineIcon from "mdi-react/PlusCircleOutlineIcon.js";
import MinusCircleOutlineIcon from "mdi-react/MinusCircleOutlineIcon.js";
import SwapHorizontalCircleOutlineIcon from "mdi-react/SwapHorizontalCircleOutlineIcon.js";
import Money100Icon from "mdi-react/Money100Icon.js";
import FireIcon from "mdi-react/FireIcon.js";
import {TRANSACTION_TYPES} from "../../../../../../back-end/constants/transactions.js";

export default ({advanceWizard}) => {

    const dispatch = useDispatch()

    const onClick = type => {
        dispatch(setType(type))
        advanceWizard()
    }

    return (
        <div className='step-type'>
            <Button
                onClick={() => onClick(TRANSACTION_TYPES.BUY)}
            >
                <PlusCircleOutlineIcon/>
                <span>
                    {TRANSACTION_TYPES.BUY}
                </span>
            </Button>
            <Button
                onClick={() => onClick(TRANSACTION_TYPES.SELL)}
            >
                <MinusCircleOutlineIcon/>
                <span>
                    {TRANSACTION_TYPES.SELL}
                </span>
            </Button>
            <Button
                onClick={() => onClick(TRANSACTION_TYPES.SWAP)}
            >
                <SwapHorizontalCircleOutlineIcon/>
                <span>
                    {TRANSACTION_TYPES.SWAP}
                </span>
            </Button>
            <Button
                onClick={() => onClick(TRANSACTION_TYPES.DIVIDEND)}
            >
                <Money100Icon/>
                <span>
                    {TRANSACTION_TYPES.DIVIDEND}
                </span>
            </Button>
            <Button
                onClick={() => onClick(TRANSACTION_TYPES.GAS)}
                className='is-gas'
            >
                <FireIcon/>
                <span>
                    {TRANSACTION_TYPES.GAS}
                </span>
            </Button>
        </div>
    )
}