import React, {useEffect, useState} from "react";
import ListLoader from "./LazyListLoader.jsx";

const useLazyListLoader = (list, renderLimitInit = 25, step = 20) => {

    const [renderLimit, setRenderLimit] = useState(renderLimitInit);
    const hasMoreItems = list.length > renderLimit

    const increaseRenderLimit = () => setRenderLimit(renderLimit + step);

    useEffect(() => {
        setRenderLimit(renderLimitInit);
    }, [list.length, renderLimitInit]);

    return {
        visibleList: list.slice(0, renderLimit),
        increaseRenderLimit,
        hasMoreItems,
        renderLimit,
        setRenderLimit,
        step,
        ListLoaderComponent: () => (
            <ListLoader
                conditionSelector={() => hasMoreItems}
                action={increaseRenderLimit}
            />
        )
    }

};

export default useLazyListLoader