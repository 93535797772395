export default ({
                    isActive = false,
                    previousButtonText = 'Previous step',
                    skipButtonText = 'Skip',
                    nextButtonText = 'Next step',
                    displayNavigation = true,
                    isSkippable = false,
                    isFinalStep = false,
                    isValid = false,
                    ...restOfProps
}) => {
    const activeClass = isActive ? 'visible' : 'hidden'
    return (
        <div
            className={`ui-wizard__steps__step ${activeClass}`}
            tabIndex={-1}
        >
            <div
                className="ui-wizard__steps__step__content" {...restOfProps}
            />
        </div>
    )
}