import './Chart.scss'
import {Chart} from "react-chartjs-2";
import {useRef, useState} from "react";
import ChartDuration from "./ChartDuration.jsx";
import ChartFocus from "./ChartFocus.jsx";
import {useChartData} from "./useChartData.js";
import {useChillEffect} from "../../util/mounting-hooks.js";
import {isMobile} from "../../util/misc.js";
import {useSelector} from "react-redux";
import {getFilter} from "../../ducks/assets-duck.js";
import {CHART_PALETTE} from "../dashboard/_chart-palette.js";
import {getIsAthGolden, getIsZeroBaseValue, getShowLatestValue} from "../../ducks/chart-duck.js";

const durationToLineThickness = daysDuration => {
    if(daysDuration < 33){
        return 5
    }
    if(daysDuration < 364){
        return 4
    }
    if(daysDuration < 364 * 2){
        return 3
    }
    return 2
}


export default () => {

    const filter = useSelector(getFilter)
    const isLatestLineVisible = useSelector(getShowLatestValue)
    const isZeroBaseValue = useSelector(getIsZeroBaseValue)
    const isAthGolden = useSelector(getIsAthGolden)

    const chartRef = useRef()
    const {labels, values, isATH, isLatestMovePositive, onMouseMove, onMouseOut} = useChartData()

    const [data, setData] = useState({labels: [], datasets: []})

    useChillEffect(() => {

        const chartArea = chartRef.current?.chartArea

        const ctx = document.createElement('canvas')?.getContext("2d")

        const backgroundGradient = ctx.createLinearGradient(0, chartArea?.bottom || 0, 0, chartArea?.top || 500);

        let palette
        if (isATH && isAthGolden) {
            palette = CHART_PALETTE.GOLDEN
        } else {
            palette = isLatestMovePositive ? CHART_PALETTE.POSITIVE : CHART_PALETTE.NEGATIVE
        }

        const {lineColor1, lineColor2, fillColor1, fillColor2} = palette

        const linearGradient = ctx.createLinearGradient(chartArea?.left || 0, 0, chartArea?.right || 0, 0);
        linearGradient.addColorStop(1, lineColor1);
        linearGradient.addColorStop(0, lineColor2);
        backgroundGradient.addColorStop(1, fillColor1);
        backgroundGradient.addColorStop(0, fillColor2);

        const linearGradient2 = ctx.createLinearGradient(chartArea?.left || 0, 0, chartArea?.right || 0, 0);
        linearGradient2.addColorStop(1, "rgba(136,94,178,.75)");
        linearGradient2.addColorStop(.2, "rgba(136,94,178,.1)");

        const lineThickness = durationToLineThickness(values.length)

        setData({
            labels: labels,
            datasets: [
                {
                    label: 'Values',
                    data: values,
                    borderColor: linearGradient,
                    backgroundColor: backgroundGradient,
                    pointRadius: 0,
                    borderWidth: isMobile() ? lineThickness / 4 * 3 : lineThickness,
                    pointHitRadius: 10,
                    lineTension: 0.15,
                    fill: true,
                },
                isLatestLineVisible && {
                    label: 'Latest',
                    data: values.map(() => (values[0])),
                    borderColor: linearGradient2,
                    backgroundColor: 'transparent',
                    borderDash: [10, 5],
                    pointRadius: 0,
                    fill: false,
                },
            ].filter(dataset => !!dataset),
        })

        onMouseOut(filter)


    }, [values.toString(), isLatestLineVisible, isAthGolden])

    const options = {
        // maintainAspectRatio: false,
        elements: {
            line: {
                tension: 1
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
            },
            legend: {
                display: false,
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    round: 'date',
                    displayFormats: {
                        month: 'MMMM YYYY',
                        // hour: 'MM-DD HH:ii'
                    },
                },
                border: {
                    display: !isMobile(),
                },
                ticks: {
                    display: !isMobile(),
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: isZeroBaseValue,
                border: {
                    display: !isMobile(),
                },
                ticks: {
                    display: !isMobile(),
                },
                grid: {
                    display: false,
                },
            }
        }
    }

    return (
        <div className='chart'>
            <div className='chat-options'>
                <ChartFocus/>
                <ChartDuration/>
            </div>
            <Chart
                className={`no-select ${values.length < 1 ? 'transparent' : ''}`}
                type='line'
                data={data}
                options={options}
                ref={chartRef}
                onMouseMove={e => onMouseMove(e, chartRef)}
                onTouchMove={e => onMouseMove(e, chartRef)}
                onMouseOut={e => onMouseOut(filter)}
            />
        </div>
    )
}

