import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FocusLock from 'react-focus-lock';
import ClickOutsideCatcher from "../ClickOutsideCatcher";
import CloseIcon from "mdi-react/CloseIcon";

import './Popup.scss';
import {closePopup, getIsPopupActive, getIsPopupWide, getPopupContent} from "../../../ducks/popup-duck.js";

export default () => {

    const dispatch = useDispatch()
    const content = useSelector(getPopupContent)
    const isActive = useSelector(getIsPopupActive)
    const isWide = useSelector(getIsPopupWide)

    const close = () => {
        dispatch(closePopup())
    }

    return (
        <div id='popup' className={`${!isActive ? 'inactive' : 'active'} ${isWide ? 'is-wide' : ''} ${!content && 'no-content'}`}>
            <div
                id='popup__modal'
                className='no-select'
                onClick={close}
            />
            <Wrapper isActive={isActive} closePopup={close}>
                <div id='popup__container'>
                    <button
                        onClick={close}
                        id='popup__container__close'
                    >
                        <CloseIcon/>
                    </button>
                    <div id='popup__container__content'>
                        {content}
                    </div>
                </div>
            </Wrapper>
        </div>
    )
}

const Wrapper = ({isActive, closePopup, children}) => {
    return isActive ? <FocusLock><ClickOutsideCatcher onCatch={closePopup} children={children}/></FocusLock> : children
}