import Counter from "./Counter.jsx";
import {DECIMAL_FORMAT} from "../../util/numbers.js";

export default ({value = 0, gain = 0, className = '', decimals = DECIMAL_FORMAT.FLEX}) => {

    const cost = value - gain
    let gainPct = cost === 0 ? 1 : gain / cost
    let colorClassName = "is-grey"

    if (gain > 0) {
        colorClassName = 'is-positive'
    }
    if (gain < 0) {
        colorClassName = 'is-negative'
    }
    if (cost < 0 && gain !== 0) {
        gainPct = gain / (cost + gain)
        colorClassName = 'is-golden'
    }

    return (
        <span className={`gain-pct ${colorClassName} ${className}`}>
            <Counter
                amount={gainPct * 100}
                decimals={decimals}
                absolute={true}
                suffix='%'
            />
        </span>
    )
}