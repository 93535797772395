import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getTodayString} from "../util/time-and-date.js";
import {getAllAssets, getAllPresetFilterAssets} from "./assets-duck.js";
import {TRANSACTION_TYPES} from "../../../back-end/constants/transactions.js";

export const NAME = 'transaction-add';

const initialState = {
    type: "",
    // type: TRANSACTION_TYPES.SWAP,
    // type: TRANSACTION_TYPES.GAS,
    preselectedAssetId: undefined,
    assetId: undefined,
    // assetId: 16,
    quantity: 0,
    // quantity: 10,
    assetIdFrom: undefined,
    // assetIdFrom: 128,
    quantityFrom: 0,
    cost: 0,
    date: undefined,
    comment: "",
    isAssetFilterApplied: true,
    isCompanyTransaction: false,
    currency: 'NOK',
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setType: (state, {payload: type}) => {
            state.type = type
            if([TRANSACTION_TYPES.GAS].includes(type)){
                state.cost = 0
            }
            if([TRANSACTION_TYPES.DIVIDEND].includes(type)){
                state.quantity = 0
            }
        },
        setPreselectedAssetId: (state, {payload: preselectedAssetId}) => {
            state.preselectedAssetId = parseInt(preselectedAssetId)
        },
        setAssetId: (state, {payload: assetId}) => {
            if (!assetId) {
                state.assetId = undefined
                state.preselectedAssetId = undefined
            } else {
                state.assetId = parseInt(assetId)
            }
        },
        setAssetIdFrom: (state, {payload: assetId}) => {
            if (!assetId) {
                state.assetIdFrom = undefined
            } else {
                state.assetIdFrom = parseInt(assetId)
            }
        },
        setTransactionDate: (state, {payload: date}) => {
            state.date = date
        },
        setQuantity: (state, {payload: quantity}) => {
            state.quantity = quantity
        },
        setQuantityFrom: (state, {payload: quantity}) => {
            state.quantityFrom = quantity
        },
        setCost: (state, {payload: cost}) => {
            state.cost = cost
        },
        toggleIsCompanyTransaction: state => {
            state.isCompanyTransaction = !state.isCompanyTransaction
        },
        setIsCompanyTransaction: (state, {payload}) => {
            state.isCompanyTransaction = !!payload
        },
        setComment: (state, {payload: comment}) => {
            state.comment = comment
        },
        setCurrency: (state, {payload}) => {
            state.currency = payload
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    reset,
    setType,
    setPreselectedAssetId,
    setAssetId,
    setAssetIdFrom,
    setTransactionDate,
    setQuantity,
    setQuantityFrom,
    setCost,
    setIsCompanyTransaction,
    toggleIsCompanyTransaction,
    setComment,
    setCurrency,
} = actions

const getState = state => state[NAME]

export const getTransactionType = state => getState(state).type
export const getPreselectedAssetId = state => getState(state).preselectedAssetId
export const getAssetId = state => getState(state).assetId || getPreselectedAssetId(state)
export const getAssetIdFrom = state => getState(state).assetIdFrom
export const getTransactionDate = state => getState(state).date || getTodayString()
export const getIsAssetFilterApplied = state => getState(state).isAssetFilterApplied
export const getIsCompanyTransaction = state => getState(state).isCompanyTransaction
export const getComment = state => getState(state).comment
export const getCurrency = state => getState(state).currency

export const getIsSwap = state => getTransactionType(state) === TRANSACTION_TYPES.SWAP
export const getIsSale = state => getTransactionType(state) === TRANSACTION_TYPES.SELL
export const getIsGas = state => getTransactionType(state) === TRANSACTION_TYPES.GAS
export const getIsDividend = state => getTransactionType(state) === TRANSACTION_TYPES.DIVIDEND

export const getTransactionMultiplier = state => getIsGas(state) || getIsSale(state) || getIsDividend(state) ? -1 : 1

export const getRawQuantity = state => getState(state).quantity
export const getRawFromQuantity = state => getState(state).quantityFrom
export const getRawCost = state => getState(state).cost
export const getQuantity = state => getRawQuantity(state) * getTransactionMultiplier(state)
export const getQuantityFrom = state => getRawFromQuantity(state) * getTransactionMultiplier(state)
export const getCost = state => getState(state).cost * getTransactionMultiplier(state)

export const getAssetSelection = createSelector([getIsAssetFilterApplied, getAllAssets, getAllPresetFilterAssets], (isAssetFilterApplied, allAssets, allAssetsFiltered) =>
    isAssetFilterApplied ? allAssetsFiltered : allAssets
)
export const getIsTypeStepValid = createSelector([getTransactionType], (type) =>
    !!type
)
export const getIsAssetStepValid = createSelector([getTransactionType, getAssetId, getAssetIdFrom], (type, assetId, assetIdFrom) =>
    !!assetId && (type !== TRANSACTION_TYPES.SWAP || !!assetIdFrom)
)
export const getIsAssetDetailsValid = createSelector([getTransactionType, getQuantity, getQuantityFrom, getCost, getTransactionDate], (type, quantity, quantityFrom, cost, date) => {
    switch (type) {
        case TRANSACTION_TYPES.SWAP:
            return !!date && !!quantity && !!quantityFrom && !!cost
        case TRANSACTION_TYPES.GAS:
            return !!date && !!quantity
        case TRANSACTION_TYPES.DIVIDEND:
            return !!date && (!!cost || !!quantity)
        default:
            return !!date && !!quantity && !!cost

    }
})


export default reducer