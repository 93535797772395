import {Link} from "react-router-dom";
import {scrollToDashBoard, useNavigation} from "./navigation.js";
import './InternalLink.scss'

export default ({
                    to = '',
                    params = {},
                    scrollFn = scrollToDashBoard,
                    className = '',
                    isButton = false,
                    children = null,
                    icon = null,
                    isIconRight = false,
                    onClick = () => {}
                }) => {

    const {
        getRoute,
    } = useNavigation(scrollToDashBoard)

    const onClickFn = () => {
        onClick()
        scrollFn()
    }

    return (
        <Link
            to={getRoute(to, params)}
            onClick={onClickFn}
            className={`internal-link ${className} ${isButton && 'button'} ${!!icon && 'has-icon'} ${isIconRight && 'is-icon-right'}`}
        >
            <div className='flex-vertical-center'>
                {!isIconRight && icon}
                <div className='children'>
                    {children}
                </div>
                {isIconRight && icon}
            </div>
        </Link>
    )
}