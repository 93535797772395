import React from 'react';

import './LoaderCircle.scss';

const LoaderCircle = ({...restOfProps}) => {
    return (
        <div className='loader loader-circle' {...restOfProps} />
    )
};

export default LoaderCircle;