const JWT_KEY = 'oddfolio-jwt'

export const getTokenLocalStorage = () => localStorage.getItem(JWT_KEY)
export const setTokenLocalStorage = token => localStorage.setItem(JWT_KEY, token)
export const removeTokenLocalStorage = () => localStorage.removeItem(JWT_KEY)


export const setStorage = (key, value) => localStorage.setItem(`oddfolio-${key}`, JSON.stringify(value || null))
export const getStorage = (key, defaultValue) => {
    const storedValue = localStorage.getItem(`oddfolio-${key}`)
    return storedValue === null ? defaultValue : JSON.parse(localStorage.getItem(`oddfolio-${key}`))
}