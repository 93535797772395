import {useSetup} from "./useSetup.js";
import {useSelector} from "react-redux";
import {getCurrentStepDesc, getIsSetupFinished, getSetupLog} from "../../ducks/setup-duck.js";
import './Setup.scss'
import {numberFormat} from "../../util/numbers.js";
import {useRateRefresh} from "./useRateRefresh.js";

export default () => {

    useSetup()

    const currentStepName = useSelector(getCurrentStepDesc)
    const isSetupFinished = useSelector(getIsSetupFinished)
    const log = useSelector(getSetupLog)

    return isSetupFinished && (
        <div className='setup'>
            {log.map(({step, executionTimeMs})=>{
                return (
                    <div key={step+executionTimeMs} className='step'>
                        <div>
                            {step}
                        </div>
                        <div>
                            <b>{numberFormat(executionTimeMs)}</b>ms
                        </div>
                    </div>
                )
            })}
            {currentStepName}
        </div>
    )
}

export const RateRefresh = () => {
    useRateRefresh()
    return null
}