import {setCurrentStep, setIsSetupFinished, setSetupError, SETUP_STEPS} from "../../ducks/setup-duck.js";
import {getRatesApi, getRatesLimitedApi, getStatusApi, getTransactionsApi} from "../../api/statusApi.js";
import {setAssets} from "../../ducks/assets-duck.js";
import {setLatestUpdate, setPortfolioAggregateDateMap, setTransactions} from "../../ducks/transactions-duck.js";
import {setRates} from "../../ducks/rates-duck.js";
import {createPortfolioAggregateDateMap} from "./_portfolio-aggregate.js";
import {getAthAssets, getRatedAssets} from "./_asset-rating.js";
import {createHistory} from "./_history.js";
import {setHistory} from "../../ducks/history-duck.js";
import {setUserId} from "../../ducks/user-duck.js";

let isSetupRunning = false

export const setup = async (dispatch, doFullSetup = false, doRepeat = false) => {
    try {
        if (!isSetupRunning) {
            isSetupRunning = true

            dispatch(setCurrentStep(SETUP_STEPS.LOADING_ASSETS))
            const {assets, userId} = await getStatusApi()
            dispatch(setUserId(userId))

            dispatch(setCurrentStep(SETUP_STEPS.LOADING_TRANSACTIONS))
            const {transactions} = await getTransactionsApi()
            dispatch(setTransactions(transactions))

            dispatch(setCurrentStep(SETUP_STEPS.LOADING_RATES))
            const {ratesMap, latestUpdate} = doFullSetup ? await getRatesApi() : await getRatesLimitedApi()
            dispatch(setRates(ratesMap))
            dispatch(setLatestUpdate(latestUpdate))

            dispatch(setCurrentStep(SETUP_STEPS.BUILDING_PORTFOLIO_AGGREGATE_MAP))
            const portfolioAggregateDateMap = createPortfolioAggregateDateMap(transactions)
            dispatch(setPortfolioAggregateDateMap(portfolioAggregateDateMap))

            dispatch(setCurrentStep(SETUP_STEPS.BUILDING_HISTORY_MAP))
            const history = createHistory(assets, ratesMap, portfolioAggregateDateMap)
            dispatch(setHistory(history))

            dispatch(setCurrentStep(SETUP_STEPS.MERGING_ASSET_RATES))
            const ratedAssets = getRatedAssets(assets, ratesMap, history, portfolioAggregateDateMap)
            const athRatedAssets = getAthAssets(ratedAssets, ratesMap)
            dispatch(setAssets(athRatedAssets))

            dispatch(setCurrentStep(SETUP_STEPS.FINISHED))
            dispatch(setIsSetupFinished(true))
            isSetupRunning = false

            doRepeat && await setup(dispatch, true)
        }
    } catch (err) {
        isSetupRunning = false
        dispatch(setSetupError(err))
        console.log(err)
    }
}