import dayjs from "dayjs";

export const MS = 1
export const SEC = 1000
export const MIN = SEC * 60
export const HOUR = MIN * 60
export const DAY = HOUR * 24
export const WEEK = DAY * 7
export const MONTH = DAY * 30
export const QUARTER = MONTH * 3

export const isToday = date => dayjs().format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
export const isYesterday = date => dayjs().add(-1, 'day').format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')
// export const isToday = date => dayjs(date).date() === dayjs().date()

export const getTodayString = () => dayjs().format('YYYY-MM-DD')


export const dayFormat = days => {
    let value = days
    let suffix = 'd'
    if(days > 365){
        value = days / 365.25
        suffix = 'y'
    } else
    if(days > 30){
        value = days / 30.4375
        suffix = 'm'
    }
    return {
        value,
        suffix,
    }
}