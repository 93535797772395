export const getMaxValues = (history, labels, assetIds) => {

    const result = {
        maxValue: 0,
        maxValueDate: "",
        maxGains: 0,
        maxGainsDate: "",
        maxRate: 0,
        maxRateDate: "",
    }

    const curatedHistory = history.map(date => assetIds.reduce((acc, cur) => {
        return {
            value: acc.value + date[1][cur].value,
            gain: acc.gain + date[1][cur].gain,
            rate: acc.rate + date[1][cur].rate,
        }
    }, {
        value: 0,
        gain: 0,
        rate: 0,
    }))

    for (let i = 0; i < curatedHistory.length; i++) {
        if (result.maxValue < curatedHistory[i].value) {
            result.maxValue = curatedHistory[i].value
            result.maxValueDate = labels[i]
        }
        if (result.maxGains < curatedHistory[i].gain) {
            result.maxGains = curatedHistory[i].gain
            result.maxGainsDate = labels[i]
        }
        if (result.maxRate < curatedHistory[i].rate) {
            result.maxRate = curatedHistory[i].rate
            result.maxRateDate = labels[i]
        }
    }
    return result
}