import {useCryptoBalances} from "./_use-crypto-balances.js";
import './CryptoBalances.scss'
import Card from "../util/Card.jsx";
import ExternalLink from "../util/ExternalLink.jsx";
import Counter from "../util/Counter.jsx";
import {DECIMAL_FORMAT} from "../../util/numbers.js";
import useLazyListLoader from "../util/lasy-loader/useLazyListLoader.js";
import {formatEthereumAddress} from "../../util/misc.js";

export default () => {
    const {accounts = []} = useCryptoBalances()

    const {visibleList, ListLoaderComponent} = useLazyListLoader(accounts, 10, 3)
    return (
        <div className='crypto-balances'>
            {visibleList.map(account => <Account key={account.address} {...account} />)}
            <ListLoaderComponent />
        </div>
    )
}

const Account = ({comment, address, totalValue, location, assets = []}) => {
    return (
        <Card className='account'>
            <div className='header'>
                <div className='description'>
                    <div className='comment'>
                        {comment}
                    </div>
                    <div className='value'>
                        <Counter
                            amount={totalValue}
                            decimals={0}
                            suffix={'kr'}
                        />
                    </div>
                </div>
                <div>
                    <div className='address'>
                        <ExternalLink href={`https://etherscan.io/address/${address}`}>
                            {formatEthereumAddress(address, 6)}
                        </ExternalLink>
                    </div>
                    <div className='location'>
                        {location}
                    </div>
                </div>
            </div>
            <div className='asset-balances flex-vertical-center'>
                {assets.map(asset => <Asset key={asset.tokenAddress} address={address} {...asset} />)}
            </div>
        </Card>
    )
}

const Asset = ({code, value, balance, tokenAddress, address}) => {
    return (
        <ExternalLink
            href={`https://etherscan.io/token/${tokenAddress}?a=${address}`}
            className='asset-balance'
        >
            <div className='value'>
                <Counter
                    amount={value}
                    decimals={0}
                    suffix='kr'
                />
            </div>
            <div className='quantity'>
                <Counter
                    amount={balance}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                    suffix={code}
                />
            </div>
        </ExternalLink>
    )
}