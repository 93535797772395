import {createSlice} from "@reduxjs/toolkit";

export const NAME = 'setup';

export const SETUP_STEPS = {
    'INIT': {
        desc: 'initiating',
        weight: 1,
    },
    'LOADING_ASSETS': {
        desc: 'Loading assets',
        weight: 1,
    },
    'LOADING_TRANSACTIONS': {
        desc: 'Loading transactions',
        weight: 1,
    },
    'LOADING_RATES': {
        desc: 'Loading rates',
        weight: 1,
    },
    'MERGING_ASSET_RATES': {
        desc: 'Merging asset rates',
        weight: 1,
    },
    'BUILDING_PORTFOLIO_AGGREGATE_MAP': {
        desc: 'Building portfolio aggregate map',
        weight: 1,
    },
    'BUILDING_HISTORY_MAP': {
        desc: 'Building history map',
        weight: 1,
    },
    'FINISHED': {
        desc: 'Finished',
        weight: 0,
    },
}

const initialState = {
    isSetupFinished: false,
    currentStep: SETUP_STEPS.INIT,
    lastStepFinish: performance.now(),
    setupError: undefined,
    log: []
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setIsSetupFinished: (state, {payload: isSetupFinished}) => {
            state.isSetupFinished = isSetupFinished
        },
        setSetupError: (state, {payload: setupError}) => {
            state.setupError = setupError
        },
        setCurrentStep: (state, {payload: currentStep}) => {
            const now = performance.now()
            state.log = [
                ...state.log,
                {
                    step: state.currentStep.desc,
                    executionTimeMs: now - state.lastStepFinish
                }
            ]
            state.currentStep = currentStep
            state.lastStepFinish = now
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    setIsSetupFinished,
    setSetupError,
    setCurrentStep,
} = actions

const getState = state => state[NAME]

export const getIsSetupFinished = state => getState(state).isSetupFinished
export const getSetupError = state => getState(state).setupError

export const getSetupLog = state => getState(state).log

export const getCurrentStep = state => getState(state).currentStep
export const getCurrentStepDesc = state => getCurrentStep(state).desc



export default reducer