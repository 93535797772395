import EarthIcon from "mdi-react/EarthIcon.js";
import FileDocumentOutlineIcon from "mdi-react/FileDocumentOutlineIcon.js";
import EthereumIcon from "mdi-react/EthereumIcon.js";
import FileDocumentMultipleOutlineIcon from "mdi-react/FileDocumentMultipleOutlineIcon.js";
import {FILTER_KEY_CRYPTO, FILTER_KEY_FUNDS, FILTER_KEY_STOCKS} from "../../../ducks/assets-duck.js";
import './AssetIcon.scss'
import HomeOutlineIcon from "mdi-react/HomeOutlineIcon.js";
import DogIcon from "mdi-react/DogIcon.js";

export default ({
                    assetType = "",
                    assetId,
                    filterPreset,
                    isColored = false,
                }) => {
    let Icon = EarthIcon
    let colorClassName = ''
    if (assetType.includes('CRYPTO') || filterPreset === FILTER_KEY_CRYPTO) {
        Icon = EthereumIcon
        colorClassName = 'is-crypto'
    } else if (assetType.includes('STOCK') || filterPreset === FILTER_KEY_STOCKS) {
        Icon = FileDocumentOutlineIcon
        colorClassName = 'is-stock'
    } else if (assetType.includes('FUND') || filterPreset === FILTER_KEY_FUNDS) {
        Icon = FileDocumentMultipleOutlineIcon
        colorClassName = 'is-fund'
    } else if (assetType.includes('REAL_ESTATE')) {
        Icon = HomeOutlineIcon
        colorClassName = 'is-real-estate'
    } else if (filterPreset.includes('dawg')) {
        Icon = DogIcon
        colorClassName = 'is-real-estate'
    }
    return <Icon className={`asset-icon ${isColored ? colorClassName : ''}`}/>
}