import './Button.scss'

export default ({
                    className = '',
                    icon,
                    isIconRight = false,
                    isRed= false,
                    isChecked = false,
                    children,
                    ...restOfProps
}) => {
    let iconClass = !!icon ? 'has-icon' : ''
    if(isIconRight){
        iconClass += ' is-icon-right'
    }
    return (
        <button
            className={`button ${iconClass} ${className} ${isRed ? 'is-red' : ''} ${isChecked ? 'is-checked' : ''}`}
            {...restOfProps}
        >
            {!isIconRight && <div className='icon'>{icon}</div>}
            <div className='button-content'>
                {children}
            </div>
            {isIconRight && <div className='icon'>{icon}</div>}
        </button>
    )
}