import './AddTransactionsBulk.scss'
import Button from "../../../util/Button.jsx";
import Toggle from "../../../util/Toggle.jsx";
import {useState} from "react";
import {postTransactionsBulkApi} from "../../../../api/statusApi.js";
import {setup} from "../../../setup/_setup.js";
import {useDispatch} from "react-redux";
import Table from "../../../util/table/Table.jsx";
import CheckIcon from "mdi-react/CheckIcon.js";
import CloseIcon from "mdi-react/CloseIcon.js";
import {numberFormat} from "../../../../util/numbers.js";
import {scrollToDashBoard, useNavigation} from "../../../util/navigation/navigation.js";
import {APP_ROUTES} from "../../../../App.jsx";
import PlusIcon from "mdi-react/PlusIcon.js";

export default () => {

    const dispatch = useDispatch()

    const [text, setText] = useState("")
    const [isCompany, setIsCompany] = useState(false)
    const [isPosting, setIsPosting] = useState(false)
    const [transactions, setTransactions] = useState([])

    const postTransaction = async () => {
        setIsPosting(true)
        const transactions = await postTransactionsBulkApi({
            raw: text,
            isCompany,
        })
        setTransactions(transactions)
        await setup(dispatch, true)
        setIsPosting(false)
    }

    if (transactions.length > 0) {
        return (
            <div className='page add-transaction-bulk'>
                <h3>
                    Transactions added
                </h3>
                <Table className='import-report rounded'>
                    <tbody>
                    {transactions.map(tx => <Transaction id={tx.id} {...tx}/>)}
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <div className='page add-transaction-bulk'>
            <h3>
                Add bulk transactions
            </h3>
            <textarea
                onChange={e => setText(e.target.value)}
                value={text}
            />
            <div className='options flex-vertical-center'>
                <Button
                    onClick={postTransaction}
                    disabled={isPosting}
                    icon={<PlusIcon />}
                >
                    Add
                </Button>
                <Toggle
                    isChecked={isCompany}
                    onChange={() => setIsCompany(!isCompany)}
                >
                    Company transactions
                </Toggle>
            </div>
        </div>
    )
}

const Transaction = ({
                         id,
                         isImported,
                         assetName,
                         date,
                         cost,
                     }) => {
    const {navigateTo} = useNavigation(scrollToDashBoard)
    return (
        <tr onClick={() => navigateTo(APP_ROUTES.transactionsPage, {transactionId: id})}>
            <td>
                {isImported ? <CheckIcon className='is-positive'/> : <CloseIcon className='is-negative'/>}
            </td>
            <td>
                {isImported ? 'Imported' : 'Already in db'}
            </td>
            <td>
                {date}
            </td>
            <td>
                {assetName}
            </td>
            <td className='numeric'>
                {numberFormat(cost)}
            </td>
        </tr>
    )
}