import PlusBoldIcon from "mdi-react/PlusBoldIcon.js";
import MinusThickIcon from "mdi-react/MinusThickIcon.js";
import './AdditiveIcon.scss'

export default ({amount = 0}) => {
    if(amount > 0){
        return <PlusBoldIcon className='additive-icon is-positive'/>
    }
    if(amount < 0){
        return <MinusThickIcon className='additive-icon is-negative'/>
    }
    return null
}