import React, {Component} from 'react';

import './counter.scss';
import {numberFormat} from "../../util/numbers.js";

// VERY old component - should be refactored and upgraded :P

class Counter extends Component {

    state = {
        amount: this.props.amount || 0,
        transition: false,
        targetAmount: 0,
        decimals: this.props.decimals || 0,
        positivity: 0,
        defaultHeader: document.title,
        updateTitle: this.props.updateTitle || false,
        speed: 1,
    }
    timeOut = 0

    componentWillReceiveProps(nextProps) {
        if (nextProps.amount !== this.state.targetAmount || this.state.targetAmount === 0) {
            if (this._ismounted) this.setState({
                targetAmount: nextProps.amount,
                decimals: nextProps.decimals || 0,
            }, () => {
                this.count()
            })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeOut)
        this._ismounted = false
    }

    componentDidMount() {
        if (this.props.debug) {
            console.log(this.props.amount)
        }
        this._ismounted = true
    }

    count = () => {

        const {amount, targetAmount, decimals, speed} = this.state
        const {ignoreZeroJump} = this.props

        let newAmount = 0
        let diff = amount - targetAmount
        let positivity = 0
        if (diff < 0) {
            positivity = 1
        }
        if (diff > 0) {
            positivity = -1
        }
        if (amount !== targetAmount) {
            const limit = decimals <= 0 ? .001 : .002 / (40 * decimals)
            if (Math.abs(diff) < limit || (amount === 0 && !ignoreZeroJump)) {
                if (this._ismounted) this.setState({
                    amount: targetAmount,
                    transition: false,
                    positivity: positivity,
                })
            } else {
                newAmount = amount - diff * (.2 * speed);
                if (this._ismounted) this.setState({
                    amount: newAmount,
                    transition: true,
                    positivity: positivity,
                }, () => {
                    this.timeOut = setTimeout(this.count, 50)
                })
            }
        } else {
            clearTimeout(this.timeOut)
        }
    }

    updateHeader = amount => {
        if (!this.state.updateTitle) return null
        document.title = Math.round(amount) + " • " + this.state.defaultHeader
    }

    render() {

        let {decimals, prefix, suffix, className} = this.props
        const {smartDecimals, absolute, noColor, debug, addendum = 0} = this.props
        const {amount, positivity} = this.state

        const classNames = ['counter', className];
        classNames.push(this.state.transition ? 'changing' : 'stable')
        if (positivity > 0 && !noColor) {
            classNames.push('positive')
        }
        if (positivity < 0 && !noColor) {
            classNames.push('negative')
        }
        if (this.props.bold) {
            classNames.push('bold')
        }

        if (smartDecimals) {
            if (Math.abs(amount) > 250) {
                decimals = -1
            } else if (amount === 0 || Math.round(amount) === amount) {
                decimals = 0
            } else if (Math.abs(amount) < 0.0002) {
                decimals = 8
            } else if (Math.abs(amount) < 0.02) {
                decimals = 6
            } else if (Math.abs(amount) < 2) {
                decimals = 4
            } else {
                decimals = 2
            }
        }

        if (debug) {
            console.log(amount, decimals)
            console.log(typeof Math.round(amount))
            console.log(typeof amount)
            console.log(Math.round(amount) === amount)
        }

        const displayAmount = amount + addendum

        this.updateHeader(displayAmount)

        const delimiter = ','
        const formattedAmount = "" + numberFormat((absolute ? Math.abs(displayAmount) : displayAmount), decimals, smartDecimals)
        const [number, magnitude] = formattedAmount.match(/[a-z]+|[^a-z]+/gi)
        const numberParts = number.split(delimiter)

        return (
            <span className={classNames.join(' ')}>
                {prefix && <span className="counter-prefix">{prefix}</span>}
                <span className="counter-amount">
                    <span className="digits">
                        {numberParts[0]}
                    </span>
                    {numberParts[1] &&
                    <span className="decimals">
                        {delimiter}{numberParts[1]}
                    </span>
                    }
                    {magnitude &&
                    <span className={`magnitude ${magnitude}`}>
                        {magnitude}
                    </span>
                    }
                </span>
                {suffix && <span className="counter-suffix">{suffix}</span>}
            </span>
        )
    }
}

export default ({
                    amount = 0,
                    decimals = 0,
                    prefix,
                    suffix,
                    className,
                    absolute = false,
                    smartDecimals = false,
                    noColor = false,
                    debug = false,
                    bold = false,
                    updateTitle = false,
                    addendum = 0,
                    speed = 1,
                }) => {
    return (
        <Counter
            amount={isNaN(amount) ? 0 : amount}
            decimals={decimals}
            prefix={prefix}
            suffix={suffix}
            className={className}
            absolute={absolute}
            smartDecimals={smartDecimals}
            noColor={noColor}
            debug={debug}
            bold={bold}
            updateTitle={updateTitle}
            addendum={addendum}
            speed={speed}
        />
    )
}