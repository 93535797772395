export const getRatedAssets = (assets, ratesMap) => {
    return assets.map(asset => {
        const rates = findLatestRates(ratesMap, asset.id)
        const currentRate = rates.currentRate || asset.currentRate
        const currentRateUsd = rates.currentRateUsd || asset.currentRateUsd
        const currentRateDate = rates.currentRateDate || asset.currentRateDate
        const prevRate = rates.prevRate || asset.prevRate
        const prevRateDate = rates.prevRateDate || asset.prevRateDate
        const value = currentRate * asset.amount
        const change = 0
        const changePct = (currentRate - prevRate) / prevRate
        const changePctAbs = Math.abs(changePct)
        const gain = value - asset.cost
        const gainPct = gain / asset.cost
        const superGainPct = asset.cost < 0 && gain > 0 ? gain / value : 0
        return {
            ...asset,
            currentRate,
            currentRateUsd,
            currentRateDate,
            prevRate,
            prevRateDate,
            value,
            change,
            changePct,
            changePctAbs,
            gain,
            gainPct,
            superGainPct,
        }
    })
}

const findLatestRates = (ratesMap, assetId) => {

    let currentRate
    let currentRateUsd
    let currentRateDate
    let prevRate
    let prevRateDate
    
    const dates = Object.keys(ratesMap)
    for(let i = dates.length - 1;i > 0;i--){
        const date = dates[i]
        const rate = ratesMap[date][assetId]?.r
        const usdRate = ratesMap[date][assetId]?.usd
        if(rate){
            if(!currentRate){
                currentRateUsd = usdRate
                currentRate = rate
                currentRateDate = date
            } else
            if (currentRate && currentRate !== rate) {
                prevRate = rate
                prevRateDate = date
                break
            } else
            if(currentRate && currentRate === rate){
                currentRateDate = date
            }
        }
    }

    if(!prevRate){
        prevRate = currentRate
        prevRateDate = currentRateDate
    }

    return {
        currentRate,
        currentRateUsd,
        currentRateDate,
        prevRate,
        prevRateDate,
    }
}

export const getAthAssets = (assets = {}, ratesMap = {}) => {
    if(!assets || !ratesMap){
        return {}
    }
    const dates = Object.keys(ratesMap)
    for (const date of dates) {
        const dateAssets = Object.values(ratesMap[date])
        for(const dateAsset of dateAssets){
            const {r, usd, fid} = dateAsset || {}
            let asset = assets.find(asset => asset.id === fid)
            if(dateAsset && asset){
                if(!asset.rateAth || asset.rateAth < r){
                    asset.rateAth = r
                    asset.rateAthDate = date
                }
                if(!asset.rateUsdAth || asset.rateUsdAth < usd){
                    asset.rateUsdAth = usd
                    asset.rateUsdAthDate = date
                }
            }
        }
    }
    return assets
}