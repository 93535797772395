import {useQuery} from "@tanstack/react-query";
import {SEC} from "../../util/time-and-date.js";
import {ACTIONS, analyzeData, injectTimeDiffs, processLog} from "./_helpers.js";
import {median} from "../../util/numbers.js";
import {get, post} from "../../api/_fetch.js";
import {API_ROUTE_DAWG_LOG} from "../../../../back-end/constants/API-ROUTES.js";

export const getSushiLogEntries = () => get(API_ROUTE_DAWG_LOG)
export const postSushiLogEntry = data => post(API_ROUTE_DAWG_LOG, data)

export const useDogData = () => {

    const {data = [], isLoading} = useQuery({
        queryKey: ['dawg-log-api'],
        queryFn: getSushiLogEntries,
        refetchInterval: 2.5 * SEC,
    })

    const {
        msSinceLastFood,
        msSinceLastPoop,
        msSinceLastPee,
        msSinceLastAccident,
        poopTimeDiffs,
        peeTimeDiffs,
        msMedianFoodDiff,
        msMedianPoopDiff,
        msMedianPeeDiff,
        msMedianAccidentDiff,
    } = analyzeData(data)

    const medians = {
        [ACTIONS.POOP]: msMedianPoopDiff,
        [ACTIONS.PEE]: msMedianPeeDiff,
    }

    const dataPeeTimeDiffs = injectTimeDiffs(data, ACTIONS.PEE, peeTimeDiffs)
    const dataTimeDiffs = injectTimeDiffs(dataPeeTimeDiffs, ACTIONS.POOP, poopTimeDiffs)

    const processedLog = processLog(dataTimeDiffs)
    const dates = Object.keys(processedLog)

    const processedLogValues = Object.values(processedLog)
    const dailyPees = processedLogValues.map(date => date.pees)
    const dailyPoops = processedLogValues.map(date => date.poops)
    const dailyFoods = processedLogValues.map(date => date.foods)

    return {
        data: dataTimeDiffs,
        isLoading,
        processedLog,
        dates,
        medians,
        msSinceLastFood,
        msSinceLastPoop,
        msSinceLastPee,
        msSinceLastAccident,
        msMedianFoodDiff,
        msMedianPoopDiff,
        msMedianPeeDiff,
        msMedianAccidentDiff,
        medianDailyPees: median(dailyPees),
        medianDailyPoops: median(dailyPoops),
        medianDailyFoods: median(dailyFoods),
    }

}