import {combineReducers, createStore, applyMiddleware } from "@reduxjs/toolkit";
import { thunk } from 'redux-thunk'

import setupReducer, {NAME as setup} from './setup-duck.js';
import assetsReducer, {NAME as assets} from './assets-duck.js';
import userReducer, {NAME as user} from './user-duck.js';
import ratesReducer, {NAME as rates} from './rates-duck.js';
import transactionsReducer, {NAME as transactions} from './transactions-duck.js';
import addTransactionsReducer, {NAME as addTransactions} from './add-transaction-duck.js';
import historyReducer, {NAME as history} from './history-duck.js';
import chartReducer, {NAME as chart} from './chart-duck.js';
import popupReducer, {NAME as popup} from './popup-duck.js';

const rootReducer =  combineReducers({
    [setup]: setupReducer,
    [assets]: assetsReducer,
    [user]: userReducer,
    [rates]: ratesReducer,
    [transactions]: transactionsReducer,
    [addTransactions]: addTransactionsReducer,
    [history]: historyReducer,
    [chart]: chartReducer,
    [popup]: popupReducer,
})

export default createStore(rootReducer, applyMiddleware(thunk))