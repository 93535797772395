import './Dawg.scss'
import SushiDogsSushiLog from "./SushiDogsSushiLog.jsx";
import DogLogForm from "./DogLogForm.jsx";
import KPIs from "./KPIs.jsx";
import DogIcon from "mdi-react/DogIcon.js";
import DogChart from "./DogChart.jsx";

export default () => {

    return (
        <div className='dawg'>
            <h2 className='flex-vertical-center'>
                <div>
                    Sushi Dog's Sushi Log
                </div>
                <DogIcon className='std-icon'/>
            </h2>
            <DogLogForm/>
            <KPIs/>
            <DogChart/>
            <SushiDogsSushiLog/>
        </div>
    )
}