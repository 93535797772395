import {createSlice} from "@reduxjs/toolkit";

export const NAME = 'popup';

const initialState = {
    active: false,
    isWide: false,
    content: undefined
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        activateWidePopup: (state, {payload: content}) => {
            state.content = content
            state.isWide = true
            state.active = true
        },
        activatePopup: (state, {payload: content}) => {
            state.content = content
            state.isWide = false
            state.active = true
        },
        closePopup: (state) => {
            state.active = false
        },
    }
})

export const {
    activateWidePopup,
    activatePopup,
    closePopup,
} = actions

const getState = state => state[NAME]

export const getIsPopupActive = state => getState(state).active
export const getIsPopupWide = state => getState(state).isWide
export const getPopupContent = state => getState(state).content

export default reducer