export default ({className, onClick=()=>{},children}) => {
    return (
        <tr
            className={`tr-clickable ${className}`}
            onClick={onClick}
            tabIndex={0}
        >
            {children}
        </tr>
    )
}