import CreationOutlineIcon from "mdi-react/CreationOutlineIcon.js";
import StarOutlineIcon from "mdi-react/StarOutlineIcon.js";
import SkullOutlineIcon from "mdi-react/SkullOutlineIcon.js";
import './ChangeFlavorIcon.scss'
import {getDirectionColorClassName} from "../../../util/misc.js";
import TrendingDownIcon from "mdi-react/TrendingDownIcon.js";

export default ({amount = 0, isMultiColored = true}) => {
    const className = getDirectionColorClassName(amount, isMultiColored)
    if (amount >= .1) {
        return <CreationOutlineIcon className={`change-flavor-icon ${className}`}/>
    }
    if (amount >= .05) {
        return <StarOutlineIcon className={`change-flavor-icon ${className}`}/>
    }
    if (amount <= -.1) {
        return <SkullOutlineIcon className={`change-flavor-icon ${className}`}/>
    }
    if (amount <= -.05) {
        return <TrendingDownIcon className={`change-flavor-icon ${className}`}/>
    }
}