import {SEC} from "../../util/time-and-date.js";
import {ACTIONS} from "./_helpers.js";
import Card from "../util/Card.jsx";
import ActionIcon from "./ActionIcon.jsx";
import {useEffect, useState} from "react";
import Counter from "../util/Counter.jsx";
import {useDogData} from "./_use-dawg-data.js";
import TimeAgo from "./TimeAgo.jsx";

export default () => {

    const [, setTime] = useState(Date.now());

    const {
        msSinceLastFood,
        msSinceLastPoop,
        msSinceLastPee,
        msSinceLastAccident,
        msMedianFoodDiff,
        msMedianPoopDiff,
        msMedianPeeDiff,
        msMedianAccidentDiff,
    } = useDogData()

    const foodProgressPercent = msSinceLastFood / msMedianFoodDiff
    const poopProgressPercent = msSinceLastPoop / msMedianPoopDiff
    const peeProgressPercent = msSinceLastPee / msMedianPeeDiff

    const isFoodSafe = msSinceLastFood < msMedianFoodDiff * .35
    const isPoopSafe = msSinceLastPoop < msMedianPoopDiff * .35
    const isPeeSafe = msSinceLastPee < msMedianPeeDiff * .35

    const isFoodWarning = msSinceLastFood > msMedianFoodDiff * .75
    const isPoopWarning = msSinceLastPoop > msMedianPoopDiff * .75
    const isPeeWarning = msSinceLastPee > msMedianPeeDiff * .75

    const isFoodError = msSinceLastFood > msMedianFoodDiff
    const isPoopError = msSinceLastPoop > msMedianPoopDiff
    const isPeeError = msSinceLastPee > msMedianPeeDiff

    const isAccidentsPositive = msSinceLastAccident > msMedianAccidentDiff * 1.25
    const isAccidentsNegative = msSinceLastAccident < msMedianAccidentDiff
    const isAccidentsError = msSinceLastAccident < msMedianAccidentDiff * 0.25

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), SEC * 30);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return !!msSinceLastPoop && (
        <div className='flex-vertical-center kpis'>
            <KPI
                className='dynamic-display'
                isWarning={isPoopWarning}
                isError={isPoopError}
                isSafe={isPoopSafe}
                median={msMedianPoopDiff}
                header={
                    <div className='flex-vertical-center'>
                        Last poop <ActionIcon action={ACTIONS.POOP} className='std-icon'/>
                    </div>
                }
            >
                <div className='flex-vertical-center'>
                    <TimeAgo ms={msSinceLastPoop}/>
                    <Counter
                        className={'percentage'}
                        amount={poopProgressPercent * 100}
                        suffix={'%'}
                        noColor={true}
                    />
                </div>
            </KPI>
            <KPI
                className='dynamic-display'
                isWarning={isPeeWarning}
                isError={isPeeError}
                isSafe={isPeeSafe}
                median={msMedianPeeDiff}
                header={
                    <div className='flex-vertical-center'>
                        Last pee <ActionIcon action={ACTIONS.PEE} className='std-icon'/>
                    </div>
                }
            >
                <div className='flex-vertical-center'>
                    <TimeAgo ms={msSinceLastPee}/>
                    <Counter
                        className={'percentage'}
                        amount={peeProgressPercent * 100}
                        suffix={'%'}
                        noColor={true}
                    />
                </div>
            </KPI>
            <KPI
                className='dynamic-display'
                isWarning={isFoodWarning}
                isError={isFoodError}
                isSafe={isFoodSafe}
                median={msMedianFoodDiff}
                header={
                    <div className='flex-vertical-center'>
                        Last food <ActionIcon action={ACTIONS.FOOD} className='std-icon'/>
                    </div>
                }
            >
                <div className='flex-vertical-center'>
                    <TimeAgo ms={msSinceLastFood}/>
                    <Counter
                        className={'percentage'}
                        amount={foodProgressPercent * 100}
                        suffix={'%'}
                        noColor={true}
                    />
                </div>
            </KPI>
            <KPI
                className='hide-portrait'
                isSafe={isAccidentsPositive}
                isWarning={isAccidentsNegative}
                isError={isAccidentsError}
                median={msMedianAccidentDiff}
                header={
                    <div className='flex-vertical-center'>
                        Last accident
                    </div>
                }
            >
                <TimeAgo ms={msSinceLastAccident}/>
            </KPI>
        </div>
    )

}

const KPI = ({
                 children,
                 header,
                 isWarning = false,
                 isError = false,
                 isSafe = false,
                 className = '',
                 median = 0,
             }) => {
    return (
        <Card
            className={`kpi ${className}`}
            isNegative={isWarning}
            isError={isError}
            isPositive={isSafe}
        >
            <h3>
                {header}
            </h3>
            <div className={'value'}>
                {children}
            </div>
            {median &&
            <div className='median'>
                <TimeAgo ms={median}/>
                <div className='label'>median</div>
            </div>
            }
        </Card>
    )
}