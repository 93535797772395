import React from 'react';
import VisibilitySensor from "react-visibility-sensor";
import {useSelector} from "react-redux";

import './LazyListLoader.scss';

const LazyListLoader = ({conditionSelector = ()=>true, action = ()=>console.log('list loader visible')}) => {

    const isVisible = useSelector(conditionSelector)
    if(!isVisible)return null

    const onChange = isVisible =>
        isVisible && action()

    return (
        <VisibilitySensor onChange={onChange}>
            <div className='lazy-list-loader'>
                <button onClick={onChange}>
                    Load more results
                </button>
            </div>
        </VisibilitySensor>
    )
};

export default LazyListLoader