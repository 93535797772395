import {useDogData} from "./_use-dawg-data.js";
import ActionIcon from "./ActionIcon.jsx";
import {ACTIONS, isAccident, LOCATIONS} from "./_helpers.js";
import './DogChart.scss'
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {getIsDogChartVisible} from "../../ducks/user-duck.js";
import {useState} from "react";
import Toggle from "../util/Toggle.jsx";

const HOURS_IN_DAY = Array.from(Array(24).keys())

export default () => {

    const isDogChartVisible = useSelector(getIsDogChartVisible)

    const [isPeeVisible, setIsPeeVisible] = useState(true)
    const [isPoopVisible, setIsPoopVisible] = useState(true)
    const [isFoodVisible, setIsFoodVisible] = useState(true)

    const [isHighlightVisible, setIsHighlightVisible] = useState(false)
    const [highlightDate, setHighlightDate] = useState(dayjs().format("YYYY-MM-TT"))
    const [highlightAction, setHighlightAction] = useState(ACTIONS.PEE)
    const [highlightIsAccident, setHighlightIsAccident] = useState(false)

    const {
        processedLog,
        isLoading,
    } = useDogData()

    return !isLoading && (
        <div className={`dog-chart ${isDogChartVisible ? 'is-visible' : 'is-hidden'}`}>
            <div className='bg-stripes'>
                {HOURS_IN_DAY.map(n =>
                    <div className='stripe' key={n}/>
                )}
            </div>
            <div className='legend'>
                {HOURS_IN_DAY.map(n =>
                    <div key={n} style={{top: `${n / 24 * 100}%`}} className={'tick'}>
                        {n === 0 &&
                        <span className='weak'>
                            0
                        </span>
                        }
                        {n < 10 &&
                        <span className='weak'>
                            0
                        </span>
                        }
                        {n > 0 &&
                        <span>
                            {n}
                        </span>
                        }
                    </div>
                )}
            </div>
            <div className='toggles'>
                <Toggle isChecked={isPeeVisible} onChange={() => setIsPeeVisible(!isPeeVisible)}>
                    <ActionIcon action={ACTIONS.PEE} className={ACTIONS.PEE}/>
                </Toggle>
                <Toggle isChecked={isPoopVisible} onChange={() => setIsPoopVisible(!isPoopVisible)}>
                    <ActionIcon action={ACTIONS.POOP} className={ACTIONS.POOP}/>
                </Toggle>
                <Toggle isChecked={isFoodVisible} onChange={() => setIsFoodVisible(!isFoodVisible)}>
                    <ActionIcon action={ACTIONS.FOOD} className={ACTIONS.FOOD}/>
                </Toggle>
            </div>
            <div className={`highlight`}>
                <div
                    className={`highlight-content ${isHighlightVisible ? 'is-visible' : 'is-hidden'} flex-vertical-center`}>
                    <div>
                        <ActionIcon
                            action={highlightAction}
                            className={highlightIsAccident ? 'ACCIDENT' : highlightAction}
                        />
                    </div>
                    <div>
                        <div className='date is-weak'>
                            {dayjs(highlightDate).format("YYYY-MM-DD")}
                        </div>
                        <div className='time'>
                            {dayjs(highlightDate).format("HH:mm")}
                        </div>
                    </div>
                </div>
            </div>
            <div className='dates'>
                {[...Object.entries(processedLog).reverse()].map(([date, data]) =>
                    <LogDate
                        key={date}
                        date={date}
                        isPeeVisible={isPeeVisible}
                        isPoopVisible={isPoopVisible}
                        isFoodVisible={isFoodVisible}
                        setIsHighlightVisible={setIsHighlightVisible}
                        setHighlightDate={setHighlightDate}
                        setHighlightAction={setHighlightAction}
                        setHighlightIsAccident={setHighlightIsAccident}
                        {...data}
                    />
                )}
            </div>
        </div>
    )
}

const LogDate = ({entries = [], date, ...restOfProps}) => {
    const isToday = date === dayjs().format('YYYY-MM-DD')
    return (
        <div className={`date ${isToday ? 'is-today' : ''}`}>
            {[...entries.reverse()].map(event => <Event key={event.id} {...event} {...restOfProps} />)}
            {isToday &&
            <Event
                action={ACTIONS.CLOCK}
                location={LOCATIONS.OUTSIDE}
                datetime={new Date()}
                isPeeVisible={true}
            />
            }
        </div>
    )
}

const Event = ({
                   action,
                   location,
                   datetime,
                   isPeeVisible,
                   isPoopVisible,
                   isFoodVisible,
                   setIsHighlightVisible = () => {
                   },
                   setHighlightDate = () => {
                   },
                   setHighlightAction = () => {
                   },
                   setHighlightIsAccident = () => {
                   },
               }) => {
    const isThisAccident = isAccident({action, location})
    const dateObject = dayjs(datetime)
    const h = dateObject.format("H")
    const m = dateObject.format("m")
    const seconds = (h * 60 * 60) + (m * 60)
    const percent = seconds / (60 * 60 * 24) * 100

    const isVisible =
        (isPeeVisible || action !== ACTIONS.PEE) &&
        (isPoopVisible || action !== ACTIONS.POOP) &&
        (isFoodVisible || action !== ACTIONS.FOOD)

    const onMouseOver = () => {
        setIsHighlightVisible(true)
        setHighlightAction(action)
        setHighlightDate(datetime)
        setHighlightIsAccident(isThisAccident)
    }

    const onMouseOut = () => {
        setIsHighlightVisible(false)
    }

    return isVisible && (
        <div
            className={`event`} style={{top: `${percent}%`}}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <ActionIcon action={action} className={isThisAccident ? 'ACCIDENT' : action}/>
        </div>
    )
}