import './Nav.scss'
import {Link, NavLink} from "react-router-dom";
import {
    FILTER_KEY_ALL,
    FILTER_KEY_CRYPTO,
    FILTER_KEY_FUNDS,
    FILTER_KEY_STOCKS,
    getFilterPreset
} from "../../ducks/assets-duck.js";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import ChevronRightIcon from "mdi-react/ChevronRightIcon.js";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon.js";
import PlusIcon from "mdi-react/PlusIcon.js";
import {scrollToDashBoard, useNavigation} from "../util/navigation/navigation.js";
import {useSwipeable} from "react-swipeable";
import {activateWidePopup} from "../../ducks/popup-duck.js";
import Settings from "../settings/Settings.jsx";
import AssetIcon from "../util/icons/AssetIcon.jsx";
import {APP_ROUTES} from "../../App.jsx";
import CalendarIcon from "mdi-react/CalendarIcon.js";
import SwapHorizontalBoldIcon from "mdi-react/SwapHorizontalBoldIcon.js";
import GaugeIcon from "mdi-react/GaugeIcon.js";
import FormatListBulletedIcon from "mdi-react/FormatListBulletedIcon.js";
import DogIcon from "mdi-react/DogIcon.js";
import {getIsOddy, getIsWinnie} from "../../ducks/user-duck.js";

let timeout = 0

export default () => {

    const dispatch = useDispatch()
    const filterPreset = useSelector(getFilterPreset)
    const isOddy = useSelector(getIsOddy)
    const isWinnie = useSelector(getIsWinnie)
    const {getRoute/*, path*/} = useNavigation()

    const hasAccess = isOddy || isWinnie
    // const currentSubPath = path.split('/').slice(2, 3).join('/')

    const [isExtended, setIsExtended] = useState(false)

    // const startCloseTimer = () => {
    //     clearTimeout(timeout)
    //     timeout = setTimeout(() => setIsExtended(false), 1.5 * SEC)
    // }

    const instaClose = () => {
        setIsExtended(false)
        clearTimeout(timeout)
    }

    const leftHandlers = useSwipeable({
        onSwipedLeft: (eventData) => {
            clearTimeout(timeout)
            setIsExtended(true)
        }
    })

    const rightHandlers = useSwipeable({
        onSwipedRight: (eventData) => {
            clearTimeout(timeout)
            setIsExtended(false)
        }
    })

    return (
        <>
            <nav
                className={`main-nav ${isExtended ? 'is-extended' : 'is-hidden'}`}
                // onClick={startCloseTimer}
                onClick={instaClose}
                {...rightHandlers}
            >
                { /* eslint-disable-next-line */}
                <a onClick={() => dispatch(activateWidePopup(<Settings/>))}>
                    <PlusIcon/>
                </a>
                {hasAccess &&
                <Link
                    // to={`/${FILTER_KEY_ALL}/${currentSubPath}`}
                    to={getRoute(APP_ROUTES.dawg)}
                    className={filterPreset === 'dawg' ? 'is-selected' : ''}
                >
                    <DogIcon />
                </Link>
                }
                <div className='separator'/>
                <Link
                    // to={`/${FILTER_KEY_ALL}/${currentSubPath}`}
                    to={getRoute(APP_ROUTES.filter, {filterId: FILTER_KEY_ALL,})}
                    className={filterPreset === FILTER_KEY_ALL ? 'is-selected' : ''}
                    onClick={scrollToDashBoard}
                >
                    <AssetIcon filterPreset={FILTER_KEY_ALL}/>
                </Link>
                <Link
                    // to={`/${FILTER_KEY_CRYPTO}/${currentSubPath}`}
                    to={getRoute(APP_ROUTES.filter, {filterId: FILTER_KEY_CRYPTO,})}
                    className={filterPreset === FILTER_KEY_CRYPTO ? 'is-selected' : ''}
                    onClick={scrollToDashBoard}
                >
                    <AssetIcon filterPreset={FILTER_KEY_CRYPTO}/>
                </Link>
                <Link
                    // to={`/${FILTER_KEY_STOCKS}/${currentSubPath}`}
                    to={getRoute(APP_ROUTES.filter, {filterId: FILTER_KEY_STOCKS,})}
                    className={filterPreset === FILTER_KEY_STOCKS ? 'is-selected' : ''}
                    onClick={scrollToDashBoard}
                >
                    <AssetIcon filterPreset={FILTER_KEY_STOCKS}/>
                </Link>
                <Link
                    // to={`/${FILTER_KEY_FUNDS}/${currentSubPath}`}
                    to={getRoute(APP_ROUTES.filter, {filterId: FILTER_KEY_FUNDS,})}
                    className={filterPreset === FILTER_KEY_FUNDS ? 'is-selected' : ''}
                    onClick={scrollToDashBoard}
                >
                    <AssetIcon filterPreset={FILTER_KEY_FUNDS}/>
                </Link>
                { /* eslint-disable-next-line */}
                <a className='insta-close-button' onClick={instaClose}>
                    <ChevronRightIcon filterPreset={FILTER_KEY_FUNDS}/>
                </a>
            </nav>
            <nav
                className={`sub-nav ${isExtended ? 'is-extended' : 'is-hidden'}`}
                onClick={instaClose}
                {...rightHandlers}
            >
                <NavLink
                    to={getRoute(APP_ROUTES.filter, {filterId: filterPreset,})}
                    activeClassName="is-active"
                    exact={true}
                >
                    <FormatListBulletedIcon/>
                </NavLink>
                <NavLink
                    to={getRoute(APP_ROUTES.history)}
                    activeClassName="is-active"
                >
                    <CalendarIcon/>
                </NavLink>
                <NavLink
                    to={getRoute(APP_ROUTES.transactions)}
                    activeClassName="is-active"
                >
                    <SwapHorizontalBoldIcon/>
                </NavLink>
                <NavLink
                    to={getRoute(APP_ROUTES.stats)}
                    activeClassName="is-active"
                >
                    <GaugeIcon/>
                </NavLink>
            </nav>
            <nav
                className={`toggler ${!isExtended ? 'is-extended' : 'is-hidden'}`}
                onClick={() => setIsExtended(true)}
                {...leftHandlers}
            >
                { /* eslint-disable-next-line */}
                <a>
                    <ChevronLeftIcon/>
                    <ChevronLeftIcon/>
                    <ChevronLeftIcon/>
                </a>
            </nav>
        </>
    )
}