import ArrowUpThickIcon from "mdi-react/ArrowUpThickIcon.js";

import './DirectionIcon.scss'
import CircleMediumIcon from "mdi-react/CircleMediumIcon.js";
import {getDirectionColorClassName} from "../../../util/misc.js";

export default ({
                    value,
                    isUpPositive = true,
                    isGolden = false,
                    isMultiColored = false,
                }) => {
    const color = isUpPositive ? getDirectionColorClassName(value, isMultiColored) : getDirectionColorClassName(value * -1, isMultiColored)
    const direction = value > 0 ? 'up' : 'down'
    if (value !== 0) {
        return (
            <ArrowUpThickIcon
                className={`direction-icon ${direction} ${color} ${isGolden && 'is-golden'}`}
            />
        )
    }
    return (
        <CircleMediumIcon
            className={`direction-icon neutral`}
        />
    )
}