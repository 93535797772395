import {useSelector} from "react-redux";
import {
    getAssetId,
    getAssetIdFrom,
    getComment,
    getCost, getCurrency,
    getIsCompanyTransaction,
    getIsSwap,
    getQuantity,
    getQuantityFrom,
    getTransactionDate,
    getTransactionType
} from "../../../../ducks/add-transaction-duck.js";
import {getSpecificAsset} from "../../../../ducks/assets-duck.js";
import {useQuery} from "@tanstack/react-query";
import {getCurrencyRate, getRatesForDateApi} from "../../../../api/statusApi.js";
import {SEC} from "../../../../util/time-and-date.js";
import {useEffect, useState} from "react";

export const useSummary = () => {

    const [currencyRate, setCurrencyRate] = useState(1)

    const date = useSelector(getTransactionDate)

    const {data: rates = []} = useQuery({
        queryKey: ['rates-api-limited', date],
        queryFn: () => getRatesForDateApi(date),
        refetchInterval: 5 * SEC,
    })

    const type = useSelector(getTransactionType)
    const isCompanyTransaction = useSelector(getIsCompanyTransaction)
    const comment = useSelector(getComment)
    const isSwap = useSelector(getIsSwap)
    const currency = useSelector(getCurrency)

    const quantity = useSelector(getQuantity) * 1
    const quantityFrom = useSelector(getQuantityFrom) * 1
    const cost = useSelector(getCost) * 1

    const assetId = useSelector(getAssetId)
    const asset = useSelector(state => getSpecificAsset(state, assetId))

    const assetIdFrom = useSelector(getAssetIdFrom)
    const assetFrom = useSelector(state => getSpecificAsset(state, assetIdFrom))

    useEffect(()=>{
        if(currency === 'NOK'){
            setCurrencyRate(1)
        } else {
            getCurrencyRate(currency).then(rate => {
                setCurrencyRate(rate)
            })
        }
    },[currency])

    return {
        valuesMainAsset: calculate(assetId, asset, rates, cost, quantity, isCompanyTransaction, currencyRate),
        valuesSecondaryAsset: calculate(assetIdFrom, assetFrom, rates, cost * -1, quantityFrom * -1, isCompanyTransaction, currencyRate),
        isCompanyTransaction,
        comment,
        currency,
        date,
        type,
        isSwap,
    }

}

const calculate = (assetId, asset, rates = [], cost, quantity, isCompany, currencyRate = 1) => {

    const assetRate = rates.find(rate => rate.fund_id === assetId)

    const name = asset?.name || assetRate?.name

    const {
        amount_private = 0,
        amount_company = 0,
        cost_private = 0,
        cost_company = 0,
    } = asset

    const currencyCost = cost * currencyRate

    const oldQuantity = isCompany ? amount_company : amount_private
    const oldCost = isCompany ? cost_company : cost_private

    const newQuantity = oldQuantity + quantity
    const newCost = oldCost + currencyCost

    const quantityChangePercent = oldQuantity === 0 ? 1 : (newQuantity - oldQuantity) / oldQuantity
    const quantityCostPercent = oldCost === 0 ? 1 : (newCost - oldCost) / oldCost

    return {
        name,
        cost: currencyCost,
        quantity,
        effectiveRate: quantity === 0 ? 0 : currencyCost / quantity,
        oldQuantity,
        newQuantity,
        quantityChangePercent,
        quantityCostPercent,
        oldCost,
        newCost,
        assetId,
        currentRate: assetRate?.rate || 0,
    }
}