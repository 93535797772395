import './AppLoader.scss'
import LoaderCircle from "../util/loaders/LoaderCircle.jsx";
import {useSelector} from "react-redux";
import {getSetupError} from "../../ducks/setup-duck.js";
import 'react-json-pretty/themes/monikai.css';
import JSONPretty from "react-json-pretty";

export default () => {

    const setupError = useSelector(getSetupError)

    return (
        <div className='app-loader'>
            {setupError ?
                <JSONPretty data={setupError} className='error' /> :
                <LoaderCircle />
            }
        </div>
    )
}