import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@tanstack/react-query";
import {getRatesLimitedApi} from "../../api/statusApi.js";
import {SEC} from "../../util/time-and-date.js";
import {getRates} from "../../ducks/rates-duck.js";
import {getLatestUpdate, getPortfolioAggregateDateMap, setLatestUpdate} from "../../ducks/transactions-duck.js";
import {getAllAssets, setAssets} from "../../ducks/assets-duck.js";
import {getRatedAssets} from "./_asset-rating.js";
import {createHistory} from "./_history.js";
import {setHistory} from "../../ducks/history-duck.js";
import {useChillEffect} from "../../util/mounting-hooks.js";
import {setup} from "./_setup.js";

export const useRateRefresh = () => {

    const dispatch = useDispatch()
    const ratesMapState = useSelector(getRates)
    const assets = useSelector(getAllAssets)
    const latestUpdateState = useSelector(getLatestUpdate)
    const portfolioAggregateDateMap = useSelector(getPortfolioAggregateDateMap)

    const {isPending, error, data = {}} = useQuery({
        queryKey: ['rates-api-limited'],
        queryFn: getRatesLimitedApi,
        refetchInterval: 5 * SEC,
    })

    const {ratesMap: ratesMapApi = {}, date, latestUpdate: latestUpdateApi} = data

    useChillEffect(() => {

        const ratesMapNew = {
            ...ratesMapState,
        }

        for (const date of Object.keys(ratesMapApi)) {
            ratesMapNew[date] = {
                ...ratesMapState[date],
                ...ratesMapApi[date],
            }
        }

        const stateEntries = Object.entries(ratesMapState)
        const apiEntries = Object.entries(ratesMapApi)

        const latestDateApi = !!apiEntries[apiEntries.length - 1] && apiEntries[apiEntries.length - 1][0]
        const latestDateState = !!stateEntries[stateEntries.length - 1] && stateEntries[stateEntries.length - 1][0]

        const ratesDateRefreshCondition = !!latestDateState && latestDateApi !== latestDateState
        const latestUpdateRefreshCondition = !!latestUpdateState && latestUpdateApi !== latestUpdateState

        if (ratesDateRefreshCondition || latestUpdateRefreshCondition) {
            setup(dispatch, true, false).then()
            return
        }

        const ratedAssets = getRatedAssets(assets, ratesMapNew)
        const historyNew = createHistory(assets, ratesMapNew, portfolioAggregateDateMap)

        dispatch(setLatestUpdate(latestUpdateApi))
        dispatch(setAssets(ratedAssets))
        dispatch(setHistory(historyNew))

    }, [date])

    return {
        isPending,
        error,
        data,
    }

}