export const CHART_PALETTE = {
    GOLDEN: {
        lineColor1: "#fd8701",
        lineColor2: "#ffdc01",
        fillColor1: "rgba(253,152,1,0.175)",
        fillColor2: "rgba(253,152,1,0)",
    },
    POSITIVE: {
        lineColor1: "#ddff01",
        lineColor2: "#60ec7c",
        fillColor1: "rgba(96,236,124,0.175)",
        fillColor2: "rgba(96,236,124,0)",
    },
    NEGATIVE: {
        lineColor1: "#7f4cb4",
        lineColor2: "#4e4357",
        fillColor1: "rgba(127,76,180,0.175)",
        fillColor2: "rgba(127,76,180,0)",
    },
}