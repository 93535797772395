import Counter from "../util/Counter.jsx";

export default ({assetName, assetCode, quantity, profit, paidAmount, soldAmount}) => {
    return (
        <tr className='asset'>
            <td>
                {assetName} {quantity === 0 && <span className='dull'>&nbsp; dividends</span>}
            </td>
            <td className='numeric'>
                <Counter
                    amount={quantity}
                    decimals={2}
                    suffix={assetCode}
                    className={quantity === 0 ? 'weak' : ''}
                />
            </td>
            <td className='numeric'>
                <Counter
                    amount={paidAmount}
                    decimals={2}
                    className={paidAmount === 0 ? 'weak' : ''}
                />
            </td>
            <td className='numeric'>
                <Counter
                    amount={quantity === 0 ? 0 : paidAmount / quantity}
                    decimals={2}
                    className={quantity === 0 ? 'weak' : ''}
                />
            </td>
            <td className='numeric'>
                <Counter
                    amount={soldAmount}
                    decimals={2}
                    className={soldAmount === 0 ? 'weak' : ''}
                />
            </td>
            <td className='numeric'>
                <Counter
                    amount={quantity === 0 ? 0 : soldAmount / quantity}
                    decimals={2}
                    className={quantity === 0 ? 'weak' : ''}
                />
            </td>
            <td className='numeric'>
                <Counter
                    amount={profit}
                    decimals={2}
                />
            </td>
        </tr>
    )
}