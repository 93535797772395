import {useDispatch} from "react-redux";
import {setup} from "./_setup.js";
import dayjs from "dayjs";
import {useChillEffect} from "../../util/mounting-hooks.js";

export const useSetup = () => {

    const dispatch = useDispatch()

    useChillEffect(() => {
        setup(dispatch, false, true).then()
    }, [dayjs().format("YYYY-MM-DD")])

}