import Button from "../../util/Button.jsx";
import './AssetUrlAdd.scss'
import {useDispatch} from "react-redux";
import {closePopup} from "../../../ducks/popup-duck.js";
import {useState} from "react";
import {postAssetUrl} from "../../../api/statusApi.js";
import {setup} from "../../setup/_setup.js";

export default ({assetId}) => {

    const dispatch = useDispatch()
    const [isPosting, setIsPosting] = useState(false)
    const [url, setUrl] = useState()

    const postLink = async () => {
        if (url) {
            setIsPosting(true)
            await postAssetUrl({url, assetId})
            await setup(dispatch, false)
            dispatch(closePopup())
        }
    }

    return (
        <div className='asset-url-add'>
            <h2>
                Add Link
            </h2>
            <input placeholder={'https://'} onChange={e => setUrl(e.target.value)}/>
            <div className='button-container'>
                <Button onClick={postLink} disabled={isPosting}>
                    Add
                </Button>
            </div>
        </div>
    )
}