import './TransactionWizard.scss'
import Wizard from "../../../util/wizard/Wizard.jsx";
import WizardStep from "../../../util/wizard/WizardStep.jsx";
import StepSelectType from "./StepSelectType.jsx";
import {
    getIsAssetDetailsValid,
    getIsAssetStepValid,
    getIsTypeStepValid,
    getTransactionType,
    reset,
    setPreselectedAssetId
} from "../../../../ducks/add-transaction-duck.js";
import {useDispatch, useSelector} from "react-redux";
import {useChillEffect} from "../../../../util/mounting-hooks.js";
import {useParams} from "react-router-dom";
import {APP_ROUTES} from "../../../../App.jsx";
import StepSelectAsset from "./StepSelectAsset.jsx";
import StepDetails from "./StepDetails.jsx";
import StepConfirmation from "./StepConfirmation.jsx";
import {useSummary} from "./_summary.js";
import {postTransactionApi} from "../../../../api/statusApi.js";
import {scrollToDashBoard, useNavigation, useSearchParams} from "../../../util/navigation/navigation.js";
import {setup} from "../../../setup/_setup.js";
import {useState} from "react";

export default () => {

    const dispatch = useDispatch()
    const {filterId} = useParams(APP_ROUTES.filter)

    const isTypeStepValid = useSelector(getIsTypeStepValid)
    const isAssetStepValid = useSelector(getIsAssetStepValid)
    const isDetailsStepValid = useSelector(getIsAssetDetailsValid)
    const [isPosting, setIsPosting] = useState(false)

    const type = useSelector(getTransactionType)

    const summary = useSummary()

    const {navigateTo} = useNavigation(scrollToDashBoard)
    const {skipTo} = useSearchParams()

    useChillEffect(() => {
        if (!isNaN(filterId)) {
            dispatch(setPreselectedAssetId(filterId))
        }
    }, [filterId])

    const postTransaction = async () => {
        if (!isTypeStepValid || !isAssetStepValid || !isDetailsStepValid) {
            return
        }
        setIsPosting(true)
        const {transactionId} = await postTransactionApi(summary)
        await setup(dispatch, true)
        dispatch(reset())
        navigateTo(APP_ROUTES.transactionsPage, {transactionId})
        setIsPosting(false)
    }

    return (
        <div className='transaction-wizard'>
            <Wizard
                onComplete={postTransaction}
                initialStep={parseInt(skipTo) || 0}
                isLoading={isPosting}
            >
                <WizardStep
                    displayNavigation={false}
                    isValid={isTypeStepValid}
                    name={type || 'Type'}
                >
                    <StepSelectType/>
                </WizardStep>
                <WizardStep
                    isValid={isAssetStepValid}
                    name={'Asset'}
                >
                    <StepSelectAsset/>
                </WizardStep>
                <WizardStep
                    isValid={isDetailsStepValid}
                    name={'Details'}
                >
                    <StepDetails/>
                </WizardStep>
                <WizardStep
                    isValid={isTypeStepValid && isAssetStepValid && isDetailsStepValid}
                    name={'Confirm'}
                    nextButtonText={'Confirm and submit'}
                >
                    <StepConfirmation/>
                </WizardStep>
            </Wizard>
        </div>
    )
}