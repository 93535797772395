import {zeroPad} from "../../util/numbers.js";

export default ({ms}) => {
    const seconds = ms / 1000
    const days = Math.floor(seconds / 60 / 60 / 24)
    const hours = Math.floor((seconds - (days * 24 * 60 * 60)) / 60 / 60)
    const minutes = Math.floor((seconds - (hours * 60 * 60)) / 60)
    return (
        <div className='flex-vertical-center time-ago'>
            {days > 0 &&
            <div>
                {zeroPad(days, 2)}
                <span className='suffix'>
                    d
                </span>
            </div>
            }
            <div>
                {zeroPad(hours, 2)}
                <span className='suffix'>
                    h
                </span>
            </div>
            {days === 0 &&
            <div>
                {zeroPad(minutes, 2)}
                <span className='suffix'>
                    m
                </span>
            </div>
            }
        </div>
    )
}