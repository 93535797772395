import React from 'react'
import ReactDOM from 'react-dom/client'
import './animations.scss'
import './index.scss'
import App from './App'
import 'chartjs-adapter-moment';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Tooltip,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, LineElement, PointElement, Filler, TimeScale, LineController);

dayjs.extend(relativeTime)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
)
