import './Table.scss'

export default ({
                    className = '',
                    children,
                    isInCard = false,
                    isRounded = false,
}) => {
    return (
        <table className={`table ${isInCard ? 'is-in-card' : ''} ${isRounded ? 'is-rounded' : ''} ${className}`}>
            {children}
        </table>
    )
}